import React from "react";
import { Container, Typography, Box, Button, styled, Grid } from "@mui/material";
import { purple } from "@mui/material/colors";

import { LocalImages } from "../Utils/strings";
import { Strings } from "../Utils/strings";
import ReusableButton from "../ui/ReuseableButton";


const listData = [
  {
    imageUrl: LocalImages.SkinWisdom,
    label: "Skin Wisdom",
    url:"https://www.amazon.com/dp/B09N36CWVT?ref=cm_sw_r_cso_cp_apin_dp_ECGSRCMCE8F2JVKMETAG_1&ref_=cm_sw_r_cso_cp_apin_dp_ECGSRCMCE8F2JVKMETAG_1&social_share=cm_sw_r_cso_cp_apin_dp_ECGSRCMCE8F2JVKMETAG_1&starsLeft=1",
  },
  {
    imageUrl: LocalImages.lifeWave,
    label: "Life Wave",
    url:"https://michaelkjaco.com/liveyoungerwithmj/",
  },
  {
    imageUrl: LocalImages.intuitiveWellness,
    label: "Intuitive Wellness",
    url:"https://intuitivewellness.michaelkjaco.com/",
  },
  {
    imageUrl: LocalImages.rootWellness,
    label: "Root Wellness",
    url:"https://therootbrands.com/",
  },
  {
    imageUrl: LocalImages.eMFIntuitive,
    label: "EMF Harmonizer",
    url:"https://emfintuitivewarrior.com/",
  },
  {
    imageUrl: LocalImages.wavwatch,
    label: "WavWatch",
    description: "Grab this amazing watch while it’s on special – use the discount code JACO100 to save $100 off your purchase TODAY!",
    url:"https://buy.wavwatch.com/products/the-wavwatch",
  },
];

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  '&:hover': {
    backgroundColor: purple[700],
  },
}));

const PartnerProducts = () => {

  return (
    <Box sx={{maxWidth:"1500px", textAlign: "center", margin: "auto", pt: 8 }}>
      <Typography
        variant="h4"
        gutterBottom
        className="font-gloucester"
        sx={{ p: 0, m: 0 }}
        fontSize={'50px'}
      >
        {Strings.shopMjPartnersAndSave}
      </Typography>
      <Typography
        variant="subtitle1"
        className="color-gray"
        fontSize={'16px'}
        sx={{ my: 2, padding: { sm: "0 20%", md: "0 35%" }, pb: 2, lineHeight: 1, fontWeight: "normal" }}
      >
        {Strings.homeIsBehind}
      </Typography>
      <Box>
        <img src={LocalImages.leaveWings} alt="" width={'120px'} />
      </Box>

      <Grid
      container
      justifyContent="center"
      alignItems={"flex-end"}
      sx={{
        textAlign: "center",
        px: { xs: 1, sm: 2, md: 6, lg: 8, xl: 8 },
        py: 3,
      }}
    >
      {listData.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box mx={2} my={2}>
          {/* Description Box */}
          {item.description && (
                <Box
                  sx={{
                    backgroundColor: "#ffb320ab",
                    borderRadius: "8px 8px 0 0",
                    padding: "10px",
                    marginTop: "10px",
                    textAlign: "left",
                    fontSize: "14px",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {item.description}
                </Box>
              )}

              {/* Clickable Product Image */}
              <Box
                component="div"
                onClick={() => window.open(item.url, "_blank")} // Open the URL in a new tab
                sx={{
                  cursor: "pointer", // Indicate that the image is clickable
                  display: "inline-block",
                }}
              >
                <img
                  src={item.imageUrl}
                  alt={item.label}
                  className="product-img"
                  style={{ maxWidth: '100%', minHeight: '250px' }}
                />
              </Box>

            <Typography
              variant="subtitle1"
              className="font-gloucester"
              sx={{ my: 2, fontSize: "25px", fontWeight: "bold" }}
            >
              {item.label}
            </Typography>

            <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <ReusableButton text="Shop Now" style={{ minHeight: "40px", p: "20px" }} 
            onClick={() => window.open(item.url, "_blank")} // Open the URL in a new tab
            s/>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  



    </Box>
  );
};

export default PartnerProducts;
