import React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { Typography, Box, Container } from "@mui/material";
import { LocalImages } from "../Utils/strings";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Strings } from "../Utils/strings";
import { Link } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const FAQ = () => {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          
          mt: 10,
          width: "100%",
          justifyContent: "center",
        }}
        // className="faq-bg"
      >
        <Typography
          variant="h4"
          gutterBottom
          className="font-gloucester"
          fontSize={'48px'}
          sx={{ p: 0, m: 0 }}
        >
          {Strings.frequentlyAskedQuestions}
        </Typography>
        <Box>
          <img src={LocalImages.leaveWings} alt="" width={'120px'} />
        </Box>


        <Box sx={{ justifyContent: "center", alignItems: "center" }}>
          
          <Box sx={{ px: {xs: "5%", sm: "10%", md: "20%"}, pb: "0%", mt: "10px"}}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="faq-content-accordion"
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={
                  expanded !== "panel1" ? (
                    <img src={LocalImages.faqaddIcon} alt="" width="30" height="30" />
                  ) : (
                    <img src={LocalImages.faqremoveIcon} alt="" width="30" height="30" />
                  )
                }
              >
                <Typography>When does Michael Jaco go Live?</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ mb: 2 }}>
                <Typography>
                  IWC shows are Tuesdays, Thursdays, and Saturdays at 5 pm EST unless Michael is traveling or at a speaking engagement.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              className="faq-content-accordion"
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                expandIcon={
                  expanded !== "panel2" ? (
                    // <AddCircleIcon sx={{ fontSize: "2rem" }} />
                    <img src={LocalImages.faqaddIcon} alt="" width="30" height="30" />
                  ) : (
                    // <RemoveCircleIcon sx={{ fontSize: "2rem",transform: "rotate(90deg)" }} />
                    <img src={LocalImages.faqremoveIcon} alt="" width="30" height="30" />
                  )
                }
              >
                <Typography>
                  What Are the IWC Memberships and What Do They Cost?


                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  You can view the membership details <a href="#planSection">here</a>.
                </Typography>

              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              className="faq-content-accordion"
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
                expandIcon={
                  expanded !== "panel3" ? (
                    <img src={LocalImages.faqaddIcon} alt="" width="30" height="30" />
                  ) : (
                    <img src={LocalImages.faqremoveIcon} alt="" width="30" height="30" />
                  )
                }
              >
                <Typography>
                  How do I cancel my subscription service?
                </Typography>

              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        
      </Box>
    </>
  );
};

export default FAQ;
