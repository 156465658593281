import React, { useState, useEffect } from "react";
import { Container, Box, Grid, Dialog, CircularProgress } from "@mui/material";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../VideoGallery/VideoGallery.css";

import Header from "../VideoGallery/Header";
import { VideoCard } from "../../Components/VideoCard/VideoCard";
import { useVideoHooks } from "../../Hooks/useVideoHooks";
import CustomVideoPlayer from "../../Components/CustomVideoPlayer";
import { randomImage } from "../../Utils/videoSupport";
import Footer from "../../Components/Footer";

const IWCVideos = () => {
  const { getIWCVideos, iwcVideos, iwcVideosFetching, iwcVideosError } =
    useVideoHooks();

  const [videoUrl, setVideoUrl] = useState({ url: "", id: "", title: "" });
  const [isLoading, setLoading] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  const handleClose = () => {
    setLoading(false);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
    setVideoUrl({ id: "", url: "", title: "" });
  };

  useEffect(() => {
    getIWCVideos(1, 9, "page");
  }, []);

  const onClickAllVideosWatchMore = () => {
    const pageNo = iwcVideos.pageNo + 1;
    getIWCVideos(pageNo, 3, "page");
  };

  return (

    <>
    <Container maxWidth="xl">
      <Header showLive={false} />
      <Container maxWidth="lg">
        <Grid container justifyContent="left">
          <h1 style={{ color: "#000" }}>IWC Exclusives</h1>
        </Grid>
        <Grid container spacing={2}>

          {iwcVideos.list.length > 0
            ? iwcVideos.list.map((item, index) => (
                <Grid item xs={12} md={4} sx={{ my: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <VideoCard
                    description={item?.synopsis}
                    imageUrl={item.cover_image}
                    timeStamp={item?.duration}
                    title={item?.new_title}
                    key={item?.id}
                    s3_video_id={item?.s3_video_id}
                    video_id={item?.video_id}
                    release_date={item?.release_date}
                  />
                </Grid>
              ))
            : null}
        </Grid>
        {iwcVideos?.list?.length > 0 ? (
          <Grid>
            <Box sx={{ justifyContent: "center", textAlign: "center", py: 4 }}>
              <div className="loadmore-btn-container">
                <button
                  className="loadmore-btn-inner-container"
                  onClick={onClickAllVideosWatchMore}
                  disabled={iwcVideos?.list?.length === 0}
                >
                  LOAD MORE
                </button>
              </div>
            </Box>
          </Grid>
        ) : null}
      </Container>
      {/* </Box> */}

      <Dialog
        open={isLoading || iwcVideosFetching}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress />
      </Dialog>

      <Dialog
        open={videoOpen}
        onClose={handleVideoClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "visible",
          },
        }}
      >
        <CustomVideoPlayer
          handleVideoClose={handleVideoClose}
          videoUrl={videoUrl}
        />
      </Dialog>
    </Container>

    <Footer />

    </>

  );
};

export default IWCVideos;
