import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import MainHeader from "../../Components/MainHeader";
import * as Yup from "yup";
import "./Discount.css";
import { LocalImages } from "../../Utils/images";

const initialValues = {
  discountName: "", 
  discountPercentage: "",
  usageLimit: "",
  startDate: "",
  endDate: "",
  discountCode: "",
  description: "", 
  status: false, 
};

const discountSchema = Yup.object().shape({
  discountName: Yup.string().required("Discount name is required"), // Validation for discount name
  discountPercentage: Yup.number()
    .required("Discount value is required")
    .min(1, "Discount value must be greater than zero"),
  usageLimit: Yup.number().required("Usage limit is required"),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date().required("End date is required"),
  description: Yup.string().required("Description is required"), // Validation for description
});

const generateRandomCode = (length = 8) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const Discount = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: discountSchema,
    onSubmit: (values) => {
      console.log("Discount Data:", values);
      setLoading(true);
      handleClose();
    },
  });

  const handleGenerateRandomCode = () => {
    formik.setFieldValue("discountCode", generateRandomCode());
  };

  const formContent = () => (
    <form onSubmit={formik.handleSubmit} className="form">
      {/* Discount Name */}
      <TextField
        fullWidth
        margin="normal"
        label="Discount Name"
        id="discountName"
        name="discountName"
        value={formik.values.discountName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.discountName && Boolean(formik.errors.discountName)}
        helperText={formik.touched.discountName && formik.errors.discountName}
      />

      {/* Discount Code */}
      <div className="discount-code-wrapper">
        <TextField
          fullWidth
          margin="normal"
          label="Discount Code"
          id="discountCode"
          name="discountCode"
          value={formik.values.discountCode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Button className="random-code" onClick={handleGenerateRandomCode} style={{ marginLeft: "10px" }}>
          Generate random code
        </Button>
      </div>

      {/* Discount Percentage */}
      <TextField
        fullWidth
        margin="normal"
        label="Discount Percentage"
        type="number"
        id="discountPercentage"
        name="discountPercentage"
        value={formik.values.discountPercentage}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.discountPercentage && Boolean(formik.errors.discountPercentage)}
        helperText={formik.touched.discountPercentage && formik.errors.discountPercentage}
      />

      {/* Usage Limit */}
      <TextField
        fullWidth
        margin="normal"
        label="Usage Limit (0 for unlimited)"
        type="number"
        id="usageLimit"
        name="usageLimit"
        value={formik.values.usageLimit}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.usageLimit && Boolean(formik.errors.usageLimit)}
        helperText={formik.touched.usageLimit && formik.errors.usageLimit}
      />

      {/* Start Date */}
      <TextField
        fullWidth
        margin="normal"
        label="Start Date"
        type="date"
        id="startDate"
        name="startDate"
        value={formik.values.startDate}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
        helperText={formik.touched.startDate && formik.errors.startDate}
        InputLabelProps={{ shrink: true }}
      />

      {/* End Date */}
      <TextField
        fullWidth
        margin="normal"
        label="End Date"
        type="date"
        id="endDate"
        name="endDate"
        value={formik.values.endDate}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
        helperText={formik.touched.endDate && formik.errors.endDate}
        InputLabelProps={{ shrink: true }}
      />

      {/* Description */}
      <TextField
        fullWidth
        margin="normal"
        label="Description"
        id="description"
        name="description"
        multiline
        rows={4}
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
      />

      {/* Status Toggle */}
      <FormControlLabel
        control={
          <Switch
            checked={formik.values.status}
            onChange={(e) => formik.setFieldValue("status", e.target.checked)}
            name="status"
            color="primary"
          />
        }
        label="Active Status"
      />

      {/* Submit Button */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="submit-button-discount"
        disabled={!formik.isValid || formik.isSubmitting}
      >
        {formik.isSubmitting ? <CircularProgress size={24} /> : "Save Discount Code"}
      </Button>
    </form>
  );

  return (
    <Box maxWidth={true} style={{ height: "100vh" }}>
      <MainHeader />
      <Grid
        container
        style={{
          background: `url(${LocalImages.bgURL}) 0 0/100% 100% no-repeat`,
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={10} sm={6} md={10} lg={4} xl={4} sx={{ mt: 15 }}>
          <Card className="discount-card">
            <Typography variant="h3" component={"h3"} className="font-gloucester title-style01">
              Generate Discount Code
            </Typography>
            <CardContent style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
              {formContent()}
            </CardContent>
          </Card>
        </Grid>

        <Box
          style={{
            height: "100px",
            width: "100%",
            backgroundImage: `url(${LocalImages.loginBottomBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center bottom",
          }}
        >
          <p></p>
        </Box>
      </Grid>

      <Dialog
        open={isLoading}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress />
      </Dialog>
    </Box>
  );
};

export default Discount;
