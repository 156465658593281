import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import CustomInput from "../../Components/Form/CustomInput";
import { isValidString } from "../../Utils/validators";
import * as Yup from "yup";
import "./resetPass.css";
import { forgotPassLink } from "../../Features/Services/resetPassword";
import { LocalImages } from "../../Utils/images";

const initialValues = {
  email: "",
};

const resetPassSchema = Yup.object().shape({
  email: Yup.string().email("Enter a valid email").required("Email is required"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setLoading(false);
    setMessage("");
  };

  const formik = useFormik({
    initialValues,
    validationSchema: resetPassSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      forgotPassLink(values.email, 
        (response) => {
          setMessage("A reset link has been sent to your email.");
          
          setLoading(false);
          
          // Clear the message after 5 seconds
          setTimeout(() => {
            setMessage("");
            formik.setFieldValue("email", ""); // Clear the email input
          }, 10000);
        },
        (error) => {
          setMessage(error);
          setLoading(false);
        }
      );
    },
  });
  

  return (
    <Box maxWidth={true} style={{ height: "100vh" }}>
      <Card style={{ padding: "4vh", display: "flex", justifyContent: "center" }}>
        <img
          src={LocalImages.logo}
          style={{ width: "180px", position: "absolute", top: "-20px" }}
          onClick={() => navigate('/')}
        />
      </Card>
      <Grid container
        style={{
          height: "100%",
          background: `url(${LocalImages.bgURL}) 0 0/100% 100% no-repeat`,
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Grid item xs={10} sm={6} md={10} lg={4} xl={4} sx={{ mt: 15 }}>
          <Card className="login-card">
            <Typography variant="h3" component={"h3"} className="font-gloucester title-style01">
              Forgot Password
            </Typography>
            <CardContent style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
              <form onSubmit={formik.handleSubmit}>
                <CustomInput
                  value={formik.values.email}
                  onChangeText={(value) => formik.setFieldValue("email", value)}
                  handleBlur={formik.handleBlur("email")}
                  isError={isValidString(formik.errors.email) && formik.touched.email}
                  errorMsg={formik.errors.email}
                  isRequired={true}
                  label={"Email"}
                  type="text"
                  placeholder={"Email"}
                  prefixIcon={
                    <img
                      src={LocalImages.user}
                      style={{ width: "60%", height: "auto" }}
                    />
                  }
                />
                <Button
                  type="submit"
                  className="reset-btn-submit"
                  disabled={!formik.isValid || isLoading}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "1rem" }}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Send Reset Link"}
                </Button>
                {message && (
                  <Typography color={message.includes("sent") ? "green" : "red"} style={{ marginTop: "1rem" }}>
                    {message}
                  </Typography>
                )}
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={isLoading}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress />
      </Dialog>
    </Box>
  );
};

export default ForgotPassword;
