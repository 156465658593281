import React, { useState, useEffect } from "react";
import { Container, Grid, Dialog, CircularProgress } from "@mui/material";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../VideoGallery/VideoGallery.css";

import { useVideoHooks } from "../../Hooks/useVideoHooks";
import CustomVideoPlayer from "../../Components/CustomVideoPlayer";
import { randomImage } from "../../Utils/videoSupport";
import Footer from "../../Components/Footer";
import { VideoCard } from "../../Components/VideoCard/VideoCard";
import MainHeader from "../../Components/MainHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";

const IWCSocial = () => {
  const {
    getSocialVideos,
    socialVideoList,
    isSocialVideoFetching,
    socialVideoErrorMsg,
  } = useVideoHooks();

  const [videoUrl, setVideoUrl] = useState({ url: "", id: "", title: "" });
  const [isLoading, setLoading] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  const handleClose = () => {
    setLoading(false);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
    setVideoUrl({ id: "", url: "", title: "" });
  };

  useEffect(() => {
    getSocialVideos();
  }, []);

  console.log(socialVideoList?.socialIssues, '------')

  return (

<>
    <MainHeader />
    <Container maxWidth="xl">

      <Container maxWidth="lg">
        <Grid container justifyContent="left" sx={{ pt: 5 }}>
          <h1 className="font-gloucester color-gray video-title01">
            Social Issues
          </h1>
        </Grid>
        {!isSocialVideoFetching ? (
          <Grid container spacing={2} justifyContent="center" display="flex">
            {socialVideoList?.socialIssues?.length > 0
              ? socialVideoList?.socialIssues?.map((item, index) => (

                  <Grid item xs={12} md={4} sx={{ my: 1, display: "flex", justifyContent: "center", alignItems: "center"}} mt={index > 2 ? "16px" : "0px"}>
                    <VideoCard
                      description={item?.synopsis}
                      imageUrl={item.cover_image}
                      timeStamp={item?.duration}
                      title={item?.new_title}
                      key={item?.id}
                      s3_video_id={item?.s3_video_id}
                      video_id={item?.video_id}
                      release_date={item?.release_date}
                    />
                  </Grid>

                ))
              : null}
          </Grid>
        ) : null}
      </Container>
      {!isSocialVideoFetching ? (
        <>
          <Container maxWidth="lg">
            <Grid container justifyContent="left" sx={{ pt: 5 }}>
              <h1 className="font-gloucester color-gray video-title01">
                Social Media
              </h1>
            </Grid>
            <Grid container spacing={2} justifyContent="center" display="flex">
              {socialVideoList?.socialMedia?.length > 0
                ? socialVideoList?.socialMedia?.map((item, index) => (
                    <Grid item xs={12} md={4} sx={{ my: 1, display: "flex", justifyContent: "center", alignItems: "center"}} mt={index > 2 ? "16px" : "0px"}>
                      <VideoCard
                        description={item?.synopsis}
                        imageUrl={item.cover_image}
                        timeStamp={item?.duration}
                        title={item?.new_title}
                        key={item?.id}
                        s3_video_id={item?.s3_video_id}
                        video_id={item?.video_id}
                        release_date={item?.release_date}
                      />
                    </Grid>
                  ))
                : null}
            </Grid>
          </Container>

          <Container maxWidth="lg">
            <Grid container justifyContent="left" sx={{ pt: 5 }}>
              <h1 className="font-gloucester color-gray video-title01">
                Social Movements
              </h1>
            </Grid>
            <Grid container spacing={2} justifyContent="center" display="flex">
              {socialVideoList?.socialMovements?.length > 0
                ? socialVideoList?.socialMovements?.map((item, index) => (
                    <Grid item xs={12} md={4} sx={{ my: 1, display: "flex", justifyContent: "center", alignItems: "center"}} mt={index > 2 ? "16px" : "0px"}>
                      <VideoCard
                        description={item?.synopsis}
                        imageUrl={item.cover_image}
                        timeStamp={item?.duration}
                        title={item?.new_title}
                        key={item?.id}
                        s3_video_id={item?.s3_video_id}
                        video_id={item?.video_id}
                        release_date={item?.release_date}
                      />
                    </Grid>
                  ))
                : null}
            </Grid>
            {/* {videoList?.allVideos?.length > 0 ? (
            <Grid>
              <Box
                sx={{ justifyContent: "center", textAlign: "center", pt: 4 }}
              >
                <div className="loadmore-btn-container">
                  <button
                    className="loadmore-btn-inner-container"
                    onClick={onClickAllVideosWatchMore}
                    disabled={videoList?.allVideos?.length === 0}
                  >
                    LOAD MORE
                  </button>
                </div>
              </Box>
            </Grid>
          ) : null} */}
          </Container>

          <Container maxWidth="lg">
            <Grid container justifyContent="left" sx={{ pt: 5 }}>
              <h1 className="font-gloucester color-gray video-title01">
                Society
              </h1>
            </Grid>
            <Grid container spacing={2} justifyContent="center" display="flex">
              {socialVideoList?.society?.length > 0
                ? socialVideoList?.society?.map((item, index) => (
                    <Grid item xs={12} md={4} sx={{ my: 1, display: "flex", justifyContent: "center", alignItems: "center"}} mt={index > 2 ? "16px" : "0px"}>
                      <VideoCard
                        description={item?.synopsis}
                        imageUrl={item.cover_image}
                        timeStamp={item?.duration}
                        title={item?.new_title}
                        key={item?.id}
                        s3_video_id={item?.s3_video_id}
                        video_id={item?.video_id}
                        release_date={item?.release_date}
                      />
                    </Grid>
                  ))
                : null}
            </Grid>
            {/* {videoList?.allVideos?.length > 0 ? (
            <Grid>
              <Box
                sx={{ justifyContent: "center", textAlign: "center", pt: 4 }}
              >
                <div className="loadmore-btn-container">
                  <button
                    className="loadmore-btn-inner-container"
                    onClick={onClickAllVideosWatchMore}
                    disabled={videoList?.allVideos?.length === 0}
                  >
                    LOAD MORE
                  </button>
                </div>
              </Box>
            </Grid>
          ) : null} */}
          </Container>
        </>
      ) : null}

      <Dialog
        open={isLoading || isSocialVideoFetching}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress />
      </Dialog>

      <Dialog
        open={videoOpen}
        onClose={handleVideoClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "visible",
          },
        }}
      >
        <CustomVideoPlayer
          handleVideoClose={handleVideoClose}
          videoUrl={videoUrl}
        />
      </Dialog>
    </Container>

    <FixedBottomNavigation />
    <Footer />

    </>
  );
};

export default IWCSocial;
