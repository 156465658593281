import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

import "./index.css";

export const LiveVideo = ({
  currentTimeStamp,
  img,
  title,
  description,
  clickHandler,
}) => {
  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          height: "55%",
          width: "60%",
        }}
        className="blink"
      >
        <img
          src={img}
          alt="live-video-thumbnail"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            boxShadow: "0px 0px 12px 4px red",
          }}
        />
        <div
          style={{
            position: "absolute",
            backgroundColor: img !== "" ? "#3E4952" : "rgba(92, 79, 92, 0.8)",
            color: "#fff",
            padding: "6px 20px",
            borderRadius: "4px",
            alignSelf: "start",
            top: "10px",
            right: "12px",
          }}
        >
          <span style={{ fontSize: "16px" }}>{currentTimeStamp}</span>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "8px",
            margin: "0px 16px 8px",
            display: "flex",
            alignItems: "center",
            width: "95%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ padding: "8px" }}>
            <h4
              style={{
                color: img !== "" ? "#fff" : "grey",
                fontSize: "16px",
                padding: "0px",
                margin: "0px",
              }}
            >
              {title}
            </h4>
            <span
              style={{
                fontSize: "12px",
                color: img !== "" ? "#C7C7C7" : "grey",
              }}
            >
              {description}
            </span>
          </div>

          <PlayCircleFilledIcon
            fontSize="large"
            sx={{ fontSize: "64px", color: "#fff" }}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: "red",
          padding: "6px 48px",
          mt: "16px",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={clickHandler}
      >
        <Typography variant="h5" component={"h5"} color={"#fff"}>
          Watch Now
        </Typography>
      </Box>
    </Grid>
  );
};
