import React, { useContext } from "react";
import { Box } from "@mui/material";
import LoginHeader from "./LoginHeader";
import NotLoginHeader from "./NotLoginHeader";
import { AuthContext } from "../Context/AuthContext";

const MainHeader = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Box>
      {isLoggedIn ? <LoginHeader /> : <NotLoginHeader />}
    </Box>
  );
};

export default MainHeader;
