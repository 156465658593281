import React, { useState, useEffect } from "react";
import { Container, Box, Grid, Dialog, CircularProgress } from "@mui/material";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../VideoGallery/VideoGallery.css";

import { VideoCard } from "../../Components/VideoCard/VideoCard";
import { useVideoHooks } from "../../Hooks/useVideoHooks";
import { randomImage } from "../../Utils/videoSupport";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";

const LatestVideos = () => {
  
  const {
    getLatestVideos,
    latestVideos,
    latestVideoFetching,
    latestVideoFetchError,
  } = useVideoHooks();

  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setLoading(false);
  };

  useEffect(() => {
    getLatestVideos(1, 9);
  }, []);

  const onClickAllVideosWatchMore = () => {
    const pageNo = latestVideos.pageNo + 1;
    getLatestVideos(pageNo, 3);
  };

  return (

    <>

    <MainHeader />
    <Container maxWidth="xl" sx={{mt: "3%"}}>
      
      <Container maxWidth="lg">
        <Grid container justifyContent="left">
          <h1 style={{ color: "#000" }}>Latest Videos</h1>
        </Grid>
        <Grid container spacing={2}>
          {latestVideos.list.length > 0
            ? latestVideos.list.map((item, index) => (
                <Grid item xs={12} md={4} sx={{ my: 1, display: "flex", justifyContent: "center", alignItems: "center"}} >
                  <VideoCard
                    description={item?.synopsis}
                    imageUrl={item.cover_image}
                    timeStamp={item?.duration}
                    title={item?.new_title}
                    key={item?.id}
                    s3_video_id={item?.s3_video_id}
                    video_id={item?.video_id}
                    release_date={item?.release_date}
                  />
                </Grid>
              ))
            : null}
        </Grid>
        {latestVideos?.list?.length > 0 ? (
          <Grid>
            <Box sx={{ justifyContent: "center", textAlign: "center", py: 4,}}>
              <div className="loadmore-btn-container">
                <button
                  className="loadmore-btn-inner-container"
                  onClick={onClickAllVideosWatchMore}
                  disabled={latestVideos?.list?.length === 0}
                >
                  LOAD MORE
                </button>
              </div>
            </Box>
          </Grid>
        ) : null}
      </Container>

      <Dialog
        open={isLoading || latestVideoFetching}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress />
      </Dialog>
    </Container>

    <FixedBottomNavigation />

    <Footer />

    </>
  );
};

export default LatestVideos;
