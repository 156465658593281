import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Dialog,
  CircularProgress,
  Typography,
} from "@mui/material";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../VideoGallery/VideoGallery.css";

import { VideoCard } from "../../Components/VideoCard/VideoCard";
import { useVideoHooks } from "../../Hooks/useVideoHooks";
import { useLocation } from "react-router-dom";
import CustomVideoPlayer from "../../Components/CustomVideoPlayer";
import { randomImage } from "../../Utils/videoSupport";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";

const SearchPage = () => {
  const {
    searchVideos,
    searchVideosList,
    isSearchVideosFetching,
    searchVideosErrorMsg,
  } = useVideoHooks();
  const { state, pathname } = useLocation();

  const [videoUrl, setVideoUrl] = useState({ url: "", id: "", title: "" });
  const [isLoading, setLoading] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  const handleClose = () => {
    setLoading(false);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
    setVideoUrl({ id: "", url: "", title: "" });
  };

  useEffect(() => {
    if (state?.searchQuery !== undefined) {
      searchVideos(state?.searchQuery);
    }
  }, [state]);

  return (

    <>

<MainHeader />
    <Container maxWidth="xl" sx={{mt: "3%"}}>

      <Container maxWidth="lg">
        <Grid container justifyContent="left">
          <h1 style={{ color: "#000" }}>Search Results</h1>
        </Grid>
        <Grid container spacing={2}>
          {isSearchVideosFetching ? (
            <Typography>Fetching...</Typography>
          ) : searchVideosList?.length > 0 ? (
            searchVideosList?.map((item, index) => (
              <Grid xs={4} md={4}>
                <VideoCard
                  description={item?.synopsis}
                  imageUrl={item.cover_image}
                  timeStamp={item?.duration}
                  title={item?.new_title}
                  key={item?.id}
                  s3_video_id={item?.s3_video_id}
                  video_id={item?.video_id}
                  release_date={item?.release_date}
                />
              </Grid>
            ))
          ) : (
            <Typography>No Search Results Found</Typography>
          )}
        </Grid>
      </Container>

      <Dialog
        open={isLoading || isSearchVideosFetching}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress />
      </Dialog>

      <Dialog
        open={videoOpen}
        onClose={handleVideoClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "visible",
          },
        }}
      >
        <CustomVideoPlayer
          handleVideoClose={handleVideoClose}
          videoUrl={videoUrl}
        />
      </Dialog>
    </Container>
    <FixedBottomNavigation />
    <Footer />
    </>
  );
};

export default SearchPage;
