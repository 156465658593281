import React, { useContext, useState } from "react";
import { Container, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import BellIcon from "@mui/icons-material/Notifications";
import UpcomingStreaming from "../Dashboard/UpcomingStreaming";
import { SideSocialLinks } from "../../Components/SideSocialLinks";
import { Strings } from "../../Utils/strings";
import LatestShowsHeader from "../../Components/LatestShowsHeader";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";

const showList = {
  0: [
    {
      timeOfTheDay: "12:00 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "02:00 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "05:00 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
  ],
  1: [
    {
      timeOfTheDay: "01:00 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "04:00 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "07:00 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
  ],
  2: [
    {
      timeOfTheDay: "03:00 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "06:00 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "08:00 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
  ],
  3: [
    {
      timeOfTheDay: "12:00 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "02:00 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "05:00 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
  ],
  4: [
    {
      timeOfTheDay: "03:00 Am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "06:00 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "08:00 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
  ],
  5: [
    {
      timeOfTheDay: "03:30 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "04:30 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "07:00 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
  ],
  6: [
    {
      timeOfTheDay: "10:00 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "11:30 am",
      title: "Show Title",
      desc: "Title Descriptions",
    },
    {
      timeOfTheDay: "02:30 pm",
      title: "Show Title",
      desc: "Title Descriptions",
    },
  ],
};

const OurShows = () => {

  
  const { isLoggedIn } = useContext(AuthContext);

  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <MainHeader />
      
      <Box>
        <Box className="top-layout-header-bg">
          <Box className="top-layout-header-bg-curve"></Box>
        </Box>

        <Box className="live-streaming-content" sx={{ top: "-250px"}}>
          <Container className="live-streaming01">
            <UpcomingStreaming
              sideTitle2={Strings.upcomingStreamingSessions}
              sideDesc={Strings.homeIsBehind}
            />
          </Container>
        </Box>
        <Box sx={{position:"relative", my:"3%"}}>
          <LatestShowsHeader
            title={Strings.ourShows}
            desc={Strings.checkOutOnWeeklyShows}
          />
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <Box className="layout-content" sx={{ width: "150px"}}></Box>
            <Box className="layout-content" sx={{ width: "150px"}}></Box>
            <Box className="layout-content" sx={{ width: "150px"}}></Box>
          </Container>
        </Box>


        <Box sx={{ }}>
          <LatestShowsHeader
            title={Strings.ourWeeklySchedule}
            desc={"April 7, 2024 - April 13, 2024"}
          />


  <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        width: { xs: "100%", sm: "90%", md: "70%" },
      }}
    >

      <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon tabs example"
        sx={{ alignSelf: "center", mb: { xs: "16px", sm: "24px", md: "32px" } }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Monday" aria-label="Monday" />
        <Tab label="Tuesday" aria-label="Tuesday" />
        <Tab label="Wednesday" aria-label="Wednesday" />
        <Tab label="Thursday" aria-label="Thursday" />
        <Tab label="Friday" aria-label="Friday" />
        <Tab label="Saturday" aria-label="Saturday" />
        <Tab label="Sunday" aria-label="Sunday" />
      </Tabs>
      </Box>

      {showList[value].map((item, index) => (
        <ScheduleLineItem
          timeOfTheDay={item.timeOfTheDay}
          title={item.title}
          desc={item.desc}
          key={item.timeOfTheDay}
        />
      ))}
      {!isLoggedIn ? (
        <Box sx={{ alignSelf: "center", mt: { xs: "16px", md: "32px" } }}>
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="watchmore-btn-inner-container-1"
          >
            <span className="watchmore-btn">LOGIN TO WATCH NOW</span>
          </button>
        </Box>
      ) : null}
    </Container>


        </Box>
      </Box>
      <Footer />
      <SideSocialLinks />
      
    </>
  );
};

export default OurShows;

const ScheduleLineItem = ({ timeOfTheDay, title, desc, handleOnClick }) => {
  return (

    <>
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        padding: { xs: "12px", md: "24px" },
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: { xs: "16px", md: "inherit" } }}>
          {timeOfTheDay}
        </Typography>
        <Box sx={{ ml: { xs: "0", sm: "32px" }, mt: { xs: "8px", sm: "0" } }}>
          <Typography
            className="font-gloucester"
            sx={{ fontWeight: "500", fontSize: { xs: "18px", md: "24px" } }}
          >
            {title}
          </Typography>
          <Typography sx={{ fontWeight: "300", fontSize: { xs: "10px", md: "12px" } }}>
            {desc}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt: { xs: "8px", sm: "0" } }}
        onClick={handleOnClick}
      >
        <BellIcon fontSize="small" />
        <Typography sx={{ fontWeight: "bold", fontSize: { xs: "12px", md: "14px" } }}>
          Remind Me
        </Typography>
      </Box>
    </Box>
    <Divider />

    <FixedBottomNavigation />
  </>

  );
};
