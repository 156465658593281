import { useState } from "react";
import {
  fetchByCollectionName,
  fetchGalleryVideos,
  fetchSocialVideos,
  listVideos,
  videoSearch,
} from "../Features/Services/Videos";

export const useVideoHooks = () => {  
  const [videoList, setVideoList] = useState({
    top3: [],
    iwc: { list: [], pageNo: 1, offset: 8 },
    allVideos: { list: [], pageNo: 1, offset: 10 },
    videoCollections: [],
    iwcVideoHelpCenter: [],
  });
  const [isVideoListLoading, setVideoListLoading] = useState(true);
  const [videoListErrorMsg, setVideoListErrorMsg] = useState("");

  // iwc state handlers
  const [iwcVideoFetching, setIwcVideoFetching] = useState(false);
  const [iwcVideoFetchError, setIwcVideoFetchError] = useState("");

  // all videos state handlers
  const [allVideoFetching, setAllVideoFetching] = useState(false);
  const [allVideoFetchError, setAllVideoFetchError] = useState("");

  // latest videos state handlers
  const [latestVideos, setLatestVideos] = useState({
    list: [],
    pageNo: 1,
    offset: 10,
  });
  const [latestVideoFetching, setLatestVideoFetching] = useState(false);
  const [latestVideoFetchError, setLatestVideoFetchError] = useState("");

  // iwc videos state handlers
  const [iwcVideos, setIwcVideos] = useState({
    list: [],
    pageNo: 1,
    offset: 8,
  });
  const [iwcVideosFetching, setIwcVideosFetching] = useState(false);
  const [iwcVideosError, setIwcVideosError] = useState("");

  // search videos state handlers
  const [searchVideosList, setSearchVideosList] = useState([]);
  const [isSearchVideosFetching, setIsSearchVideosFetching] = useState(false);
  const [searchVideosErrorMsg, setSearchVideosErrorMsg] = useState("");

  // social video state handlers
  const [socialVideoList, setSocialVideoList] = useState({
    socialIssues: [],
    socialMedia: [],
    socialMovements: [],
    society: [],
  });
  const [isSocialVideoFetching, setIsSocialVideoFetching] = useState(true);
  const [socialVideoErrorMsg, setSocialVideoErrorMsg] = useState("");

  const listAllVideos = async () => {
    setVideoListLoading(true);
    try {
      const { data } = await fetchGalleryVideos();
      setVideoListLoading(false);
      setVideoListErrorMsg("");
      setVideoList({
        top3: data?.top3,
        iwc: {
          ...videoList.iwc,
          list: data?.iwc,
        },
        allVideos: {
          ...videoList.allVideos,
          list: data?.allVideos,
        },

        videoCollections: data.videoCollections,
        iwcVideoHelpCenter: data.iwcVideoHelpCenter,
      });
    } catch (error) {
      setVideoListLoading(false);
      setVideoListErrorMsg(error?.message);
    }
  };

  const getIWCVideos = async (pageNo, offset, type = "") => {
    console.log(offset , 'nouman')
    if (type === "") {
      setIwcVideoFetching(true);
      fetchIWCVideos(pageNo, offset)
        .then((data) => {
          setIwcVideoFetching(false);
          setIwcVideoFetchError("");
          debugger
          const newIwc = [...videoList.iwc.list, ...data];
          setVideoList({
            top3: videoList.top3,
            allVideos: videoList.allVideos,
            iwc: {
              list: newIwc,
              pageNo,
              offset,
            },

            videoCollections: videoList.videoCollections,
            iwcVideoHelpCenter: videoList.iwcVideoHelpCenter,
          });
        })
        .catch((error) => {
          setIwcVideoFetching(false);
          setIwcVideoFetchError(error?.message);
        });
    } else {
      setIwcVideosFetching(true);
      fetchIWCVideos(pageNo, offset)
        .then((data) => {
          setIwcVideosFetching(false);
          setIwcVideosError("");
          const newIwc = [...iwcVideos.list, ...data];
          setIwcVideos({
            list: newIwc,
            pageNo,
            offset,
          });
        })
        .catch((error) => {
          setIwcVideosFetching(false);
          setIwcVideosError(error?.message);
        });
    }
  };

  const fetchIWCVideos = (pageNo, offset) => {
    console.log(offset , 'hello')
    return new Promise(async (resolve, reject) => {
      try {
        const payload = {
          pageNo,
          limit: offset,
          collectionName: "IWC Exclusives",
        };
        const { data } = await fetchByCollectionName(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getAllVideos = async (pageNo, offset) => {
    setAllVideoFetching(true);
    try {
      const { data } = await listVideos(pageNo, offset);
      setAllVideoFetching(false);
      setAllVideoFetchError("");
      const newAllVideos = [...videoList.allVideos.list, ...data];
      setVideoList({
        top3: videoList.top3,
        iwc: videoList.iwc,
        allVideos: {
          list: newAllVideos,
          pageNo,
          offset,
        },
        videoCollections: videoList.videoCollections,
        iwcVideoHelpCenter: videoList.iwcVideoHelpCenter,
      });
    } catch (error) {
      setAllVideoFetching(false);
      setAllVideoFetchError(error?.message);
    }
  };

  const getLatestVideos = async (pageNo, offset) => {
    setLatestVideoFetching(true);
    try {
      const { data } = await listVideos(pageNo, offset);
      setLatestVideoFetching(false);
      setLatestVideoFetchError("");
      const newList = [...latestVideos.list, ...data];
      setLatestVideos({
        list: newList,
        pageNo,
        offset,
      });
    } catch (error) {
      setLatestVideoFetching(false);
      setLatestVideoFetchError(error?.message);
    }
  };

  const searchVideos = async (searchQuery) => {
    setIsSearchVideosFetching(true);
    try {
      const { data } = await videoSearch(searchQuery);
      setIsSearchVideosFetching(false);
      setSearchVideosErrorMsg("");
      setSearchVideosList(data);
    } catch (error) {
      setIsSearchVideosFetching(false);
      setSearchVideosErrorMsg(error?.message);
    }
  };

  const getSocialVideos = async () => {
    setIsSocialVideoFetching(true);
    try {
      const { data } = await fetchSocialVideos();
      setIsSocialVideoFetching(false);
      setSocialVideoErrorMsg("");
      setSocialVideoList(data);
    } catch (error) {
      setIsSocialVideoFetching(false);
      setSocialVideoErrorMsg(error?.message);
    }
  };
  return {
    videoList,
    isVideoListLoading,
    videoListErrorMsg,
    listAllVideos,
    getIWCVideos,
    iwcVideoFetching,
    iwcVideoFetchError,
    getAllVideos,
    allVideoFetching,
    allVideoFetchError,
    getLatestVideos,
    latestVideos,
    latestVideoFetching,
    latestVideoFetchError,
    getIWCVideos,
    iwcVideos,
    iwcVideosFetching,
    iwcVideosError,
    searchVideos,
    searchVideosList,
    isSearchVideosFetching,
    searchVideosErrorMsg,
    getSocialVideos,
    socialVideoList,
    isSocialVideoFetching,
    socialVideoErrorMsg,
  };
};
