import axios from "axios";

import { Categories, Collections, Speakers, Videos } from "../../Config/url";
import { SECRETS } from "../../Utils/secrets";

const BASE_URL = SECRETS.VIDEO_BASE_URL;
const apiBaseUrl = process.env.REACT_APP_VIDEO_BASE_URL;

const fetchGalleryVideos = () => {
  const url = `${BASE_URL}${Videos.fetchGalleryVideos}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchByCollectionName = (payload) => {
  const url = `${BASE_URL}${Videos.fetchByCollectionName}`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const listVideos = (pageNo, limit) => {
  const url = `${BASE_URL}${Videos.listVideos}?pageNo=${pageNo}&limit=${limit}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const videoSearch = (searchQuery) => {
  const url = `${BASE_URL}${Videos.searchVideos}=${searchQuery}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchSocialVideos = () => {
  const url = `${BASE_URL}${Videos.fetchSocialVideos}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchCategories = () => {
  const url = `${BASE_URL}${Categories.listCategory}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchCollections = () => {
  const url = `${BASE_URL}${Collections.listCollections}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// const addVideo = (payload) => {
//   console.log("payload111", payload);

//   const url = `${BASE_URL}${Videos.addVideo}`;
//   console.log("url", url);

//   return new Promise((resolve, reject) => {
//     axios
//       .post(url, payload)
//       .then((response) => {
//         alert(response)
//         if (response.data?.error) {
//           reject(response.data?.message);
//         }
//         resolve(response?.data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };


const addVideo = async (payload) => {

  let data = JSON.stringify({
    new_title: payload.new_title,
    synopsis: payload.synopsis,
    tags: payload.tags,
    release_date: payload.release_date,
    s3_video_id: payload.s3_video_id,
    views: payload.views,
    cover_image: payload.cover_image,
    duration: "00:00",
    category_id: payload.category_id,
    collection_id: payload.collection_id,
    availability: payload.availability,
    entity_tags: payload.entity_tags,
    title: payload.title,
    speaker_name: payload.speaker_name, 
    speaker_id: payload.speaker_id,  
    object_url: payload.object_url,
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,

    url: 'https://michaelkjaco-streaming-node-app.onrender.com/api/video/addVideos',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  axios.request(config)
    .then((response) => {
      console.log("yyyy", response);
      console.log(JSON.stringify(response.data));
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}
const fetchSpeakers = () => {
  const url = `${BASE_URL}${Speakers.listSpeaker}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchLastVideo = () => {
  const url = `${BASE_URL}${Videos.getLastVideo}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const wildSearchVideos = (payload) => {
  const url = `${BASE_URL}${Videos.wildSearchVideos}`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getVideoDetailsById = (video_id) => {
  const url = `${BASE_URL}${Videos.getVideoDetailsById}=${video_id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateVideoById = (payload) => {
  const url = `${BASE_URL}${Videos.updateVideoById}`;
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload)
      .then((response) => {
        if (response.data?.error) {
          reject(response.data?.message);
        }
        resolve("Success");
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const videoCount = async () => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://michaelkjaco-streaming-node-app.onrender.com/api/videocount',
    headers: {}
  };
  
  try {
    const response = await axios.request(config);
    console.log('Response:', response.data);
    return response.data; 
  } catch (error) {
    console.error('Error fetching video count:', error);
    throw error; 
  }
};
export {
  listVideos,
  fetchGalleryVideos,
  fetchByCollectionName,
  videoSearch,
  fetchSocialVideos,
  fetchCategories,
  fetchCollections,
  addVideo,
  fetchSpeakers,
  fetchLastVideo,
  wildSearchVideos,
  getVideoDetailsById,
  updateVideoById,
  videoCount,
};
