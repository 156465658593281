const Auth = {
  login: "/dev/wp-json/jaco/v1/plan_b",
};

const Videos = {
  fetchGalleryVideos: "video/fetchGalleryVideos",
  fetchByCollectionName: "video/getVideosByCollectionName",
  listVideos: "video/listVideos",
  searchVideos: "video/searchVideos?searchQuery",
  fetchSocialVideos: "video/fetchSocialVideos",
  addVideo: "video/addVideos",
  getLastVideo: "video/getLastVideo",
  wildSearchVideos: "video/wildSearchVideos",
  getVideoDetailsById: "video/getVideoDetailsById?video_id",
  updateVideoById: "video/updateVideoById",
};

const Categories = {
  listCategory: "videoCategory/listCategory",
  addCategory: "videoCategory/addCategory",
};

const Collections = {
  listCollections: "videoCollection/listCollections",
  addCollection: "videoCollection/addCollection",
};

const Speakers = {
  listSpeaker: "videoSpeaker/listSpeaker",
  addSpeaker: "videoSpeaker/addSpeaker",
};

export { Auth, Videos, Categories, Collections, Speakers };
