import React, { useState } from 'react';
import { Modal, Box, IconButton, Grid, Typography, Checkbox, FormControlLabel, Button, Autocomplete, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReusableButton from '../../ui/ReuseableButton';
import Radio from '@mui/material/Radio';


const tags = [
    { id: 1, name: 'Kamala Harris' },
    { id: 2, name: 'Tim Walz' },
    { id: 3, name: 'Vice Presidential Pick' },
    { id: 4, name: 'Stolen Valor' },
    { id: 5, name: 'Democratic Campaign' },
    { id: 6, name: 'Political Analysis' },
    { id: 7, name: '2024 Elections' },
    { id: 8, name: 'Military Controversy' },
    { id: 9, name: 'Joe Biden' },
    { id: 10, name: 'U.S. Politics' },
    { id: 11, name: 'Breitbart' },
    { id: 12, name: 'GOP Reactions' },
    { id: 13, name: 'National Guard' },
    { id: 14, name: 'American Politics' },
    { id: 15, name: 'Election 2024' },

];

const categories = [
    { id: 1, name: 'Politics', isSelected: false },
    { id: 2, name: 'Geopolitics', isSelected: false },
    { id: 3, name: 'Health & Wellness', isSelected: false },
    { id: 4, name: 'Finance & Investment', isSelected: false },
    { id: 5, name: 'Conspiracy Theories', isSelected: false },
    { id: 6, name: 'Military & Defense', isSelected: false },
    { id: 7, name: 'Spirituality & Consciousness', isSelected: false },
    { id: 8, name: 'Technology & Innovation', isSelected: false },
    { id: 9, name: 'LegAL & Justice', isSelected: false },
    { id: 10, name: 'Environmental Issues', isSelected: false },
    { id: 11, name: 'Historical Analysis', isSelected: false },
    { id: 12, name: 'Media & Journalism', isSelected: false },
    { id: 13, name: 'Science & Research', isSelected: false },
    { id: 14, name: 'Global Security', isSelected: false },
    { id: 15, name: 'Economic Policy', isSelected: false },
    { id: 16, name: 'Human Rights', isSelected: false },
    { id: 17, name: 'Cultural Commentary', isSelected: false },
    { id: 18, name: 'Alternative Medicine', isSelected: false },
    { id: 19, name: 'Energy & Resources', isSelected: false },
    { id: 20, name: 'Education & Learning', isSelected: false },
    { id: 21, name: 'Emergency Preparedness', isSelected: false },
    { id: 22, name: 'Social Dynamics', isSelected: false },
    { id: 23, name: 'International Relations', isSelected: false },
    { id: 24, name: 'Public Health', isSelected: false },
    { id: 25, name: 'Cybersecurity & Privacy', isSelected: false },

];

const popularityOptions = [
    { id: 1, label: 'Most Liked' },
    { id: 2, label: 'Most Viewed' },
    { id: 3, label: 'Most Commented' }
];

const dates = [
    { id: 1, label: 'Today' },
    { id: 2, label: 'This Week' },
    { id: 3, label: 'This Month' },
    { id: 4, label: 'last month This Year' },

];

const durations = [
    { id: 1, label: 'Short (< 5 min)' },
    { id: 2, label: 'Medium (5-15 min)' },
    { id: 3, label: 'Long (> 15 min)' },
];

const guests = [
    { title: 'Michael Jaco' },
    { title: 'James Rink' },
    { title: 'Juan O,Savin' },
    { title: 'Raphael Conquista' },
    { title: 'Dr.Krik Elliott PHD' },
    { title: 'Dr.Carrie Madej' },
    { title: 'Scott Bennet' },
    { title: 'Patrick Bergy' },
    { title: 'Dr.Bryan Ardis' },
    { title: 'Kevin Hoyt' },
    { title: 'Cidny Young' },
    { title: 'Heather Mays' },
    { title: 'James Grundving' },
    { title: 'Lynn Scott Hagerman' },
    { title: 'Scott McKay' },
    { title: 'Ole Dammegard' },
    { title: 'Eng-Anon' },
    { title: 'Dr.Sheri Tenpenny' },
    { title: 'Dr.Russell Witte, Ph.D' },
    { title: 'Sheila Holm' },
    { title: 'Korey Newsom' },
    { title: 'Dr.Christopher' },
    { title: 'Jake Lang' },
    { title: 'Dr.Terri Dees' },
    { title: 'Scott Perez' },
    { title: 'Brad Olsen' },
    { title: 'Clayton Thomas' },
    { title: 'Michele Swinick' },
    { title: 'Michelle Lee' },
    { title: 'Victor Avila' },
    { title: 'Mark Anthony' },
    { title: 'Lewis Herms' },
    { title: 'Mike Gill' },
    { title: 'John Beaudoin' },
    { title: 'Kim Thatcher' },
    { title: 'Dennis Linthicum' },
    { title: 'Dr.Henry Ealy' },
    { title: 'Brian O,Shea' },
    { title: 'James Files' },
    { title: 'Bo Pony' },
    { title: 'Sacha Stone' },
    { title: 'Ron Partain' },
    { title: 'Alpha Warrior' },
    { title: 'Shawn Taylor' },
    { title: 'Jana Schmidt' },
    { title: 'Mary Beaver' },
    { title: 'SG Anon' },
    { title: 'Gregory Blanc Mascari' },
    { title: 'Robin Blanc Mascari' },
    { title: 'Matt Brannon' },
    { title: 'Dr.Forest Tennant' },
    { title: 'Vincent Boccarossa' },
    { title: 'Brandi Barnes' },
    { title: 'Amanda Dumenigo' },
    { title: 'Tito Ortiz' },
    { title: 'Alferd Lambremont Webre' },
    { title: 'Ricardo Bosi' },
    { title: 'Dr.Chritine Northup' },
    { title: 'David Zenne' },
    { title: 'Dr.Sally Priester' },
    { title: 'Sherronna Bishop' },
    { title: 'Sean Taylor' },
    { title: 'Janine Morigeau' },
    { title: 'Cathy O,Brien' },
    { title: 'Jack Maxey' },
    { title: 'Azsma Holiday' },
    { title: 'Alicia England' },
    { title: 'Leo Zagami' },
    { title: 'Tara Jones' },
    { title: 'Loy Brunson' },
    { title: 'Scott McKee' },
    { title: 'David "Nino" Rodriguez' },
    { title: 'Brad Wozny' },
    { title: 'Dr.Cordie Williams' },
    { title: 'David Ziga' },
    { title: 'Aj Roberts' },
    { title: 'Lois Hermann' },
    { title: 'Mitchell-Tech Support' },
    { title: 'Cathy O,Brian' },
    { title: 'Ken Rohla' },
    { title: 'Michelle Fielding' },
    { title: 'Kerry Cassidy' },
    { title: 'Jason Shukra' },
    { title: 'Melissa McAtee' },
];

const FilterModal = ({ open, onClose }) => {

    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(
        categories.reduce((acc, category) => ({ ...acc, [category.id]: category.isSelected }), {})
    );
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [selectedGuest, setSelectedGuest] = React.useState(null);
    //   select top tags logic here
    const handleCheckboxChange = (event, tagId) => {
        if (event.target.checked) {
            setSelectedTags([...selectedTags, tagId]);
        } else {
            setSelectedTags(selectedTags.filter(id => id !== tagId));
        }
    };
    const handleRemoveTag = () => {
        setSelectedTags([]);
    };
    const handleCategoryRemove = () => {
        setSelectedCategories([])
    }
    const handleDateRemove = () => {
        setSelectedDate([])
    }
    const handlePopularityRemove = () => {
        setSelectedOption([])
    }
    const handleDurationRemove = () => {
        setSelectedDuration([])
    }
    const handleGuestRemove =()=>{
        setSelectedGuest(null)
    }

    const handleClearAll = () => {
        setSelectedTags('');
        setSelectedDate('');
        setSelectedOption('');
        setSelectedDuration('');
        setSelectedGuest(null);
        setSelectedCategories([]);
    };

    const selectedTagName = tags.find(tag => selectedTags.includes(tag.id))?.name || '';
    const selectedCategoriesNames = categories.filter(category => selectedCategories[category.id]).map(category => category.name);
    const selectedPopularity = popularityOptions.find(option => selectedOption === option.id.toString())?.label || '';
    const selectedDateLabel = dates.find(date => selectedDate === date.label)?.label || '';
    const selectedDurationLabel = durations.find(duration => selectedDuration === duration.label)?.label || '';

    //  Select category logic here
    const handleCheckboxChangeCategory = (event, id) => {
        if (event.target.checked) {
            setSelectedCategories({
                [id]: true,
            });
        } else {
            setSelectedCategories({});
        }
    };

    // select popularity logic here
    const handleChange = (event) => {
        const { id } = event.target;
        setSelectedOption((prevSelected) => (prevSelected === id ? null : id));
    };

    //select date published logic here
    const handleCheckboxChangeDate = (date) => {
        if (selectedDate === date) {
            setSelectedDate(null);
        } else {
            setSelectedDate(date);
        }
    };

    // select duration logic here
    const handleCheckboxChangeDuration = (duration) => {
        if (selectedDuration === duration) {
            setSelectedDuration(null);
        } else {
            setSelectedDuration(duration);
        }
    };

    return (
        <Modal open={open} onClose={onClose} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box
                sx={{
                    width: '1300px',
                    height: '81vh',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    top: '50px',
                    borderRadius: '10px',
                    marginRight: '10px',
                    p: 4,
                    position: 'relative',
                }}
            >
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{
                    bgcolor: 'white',
                    border: 1,
                    borderColor: 'grey.300',
                    borderRadius: '8px',
                    padding: 2,
                    marginBottom: 2,
                    display: 'flex',
                    flexDirection: 'row', gap: 2, marginTop: '5px',overflowX: 'auto', whiteSpace: 'nowrap', padding: '8px 5px' 
                }}>
                    {selectedTagName && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button sx={{
                                bgcolor: '#fcb632',
                                color: 'white',
                                marginTop:'-8px',
                                '&:hover': {
                                    bgcolor: '#fcb632',
                                },
                                '&:active': {
                                    bgcolor: '#fcb632',
                                }
                            }}>
                                <span style={{ color: 'white', fontWeight: 'medium', fontSize: '12px' }}>{selectedTagName}</span>
                                <button
                                    onClick={handleRemoveTag}
                                    style={{ marginLeft: 8, cursor: 'pointer', border: 'none', background: 'transparent', color: 'white' }}
                                >
                                    <svg width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </Button>
                        </div>
                    )}                  

                    {selectedDateLabel && (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>

                            <Button
                                sx={{
                                    bgcolor: '#fcb632',
                                    color: 'white',
                                    '&:hover': {
                                        bgcolor: '#fcb632',
                                    },
                                    '&:active': {
                                        bgcolor: '#fcb632',
                                    }
                                }}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <span style={{ color: 'white', fontWeight: 'medium', fontSize: '12px' }}>
                                    {selectedDateLabel}
                                </span>
                                <button
                                    onClick={handleDateRemove}
                                    style={{ marginLeft: 8, cursor: 'pointer', border: 'none', background: 'transparent', color: 'white' }}
                                >
                                    <svg width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </Button>
                        </div>
                    )}
                    {selectedPopularity && (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>

                            <Button
                                sx={{
                                    bgcolor: '#fcb632',
                                    color: 'white',
                                    '&:hover': {
                                        bgcolor: '#fcb632',
                                    },
                                    '&:active': {
                                        bgcolor: '#fcb632',
                                    }
                                }}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <span style={{ color: 'white', fontWeight: 'medium', fontSize: '12px' }}>
                                    {selectedPopularity}
                                </span>
                                <button
                                    onClick={handlePopularityRemove}
                                    style={{ marginLeft: 8, cursor: 'pointer', border: 'none', background: 'transparent', color: 'white' }}
                                >
                                    <svg width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </Button>
                        </div>
                    )}
                    {selectedDurationLabel && (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>

                            <Button
                                sx={{
                                    bgcolor: '#fcb632',
                                    color: 'white',
                                    '&:hover': {
                                        bgcolor: '#fcb632',
                                    },
                                    '&:active': {
                                        bgcolor: '#fcb632',
                                    }
                                }}
                                style={{ display: 'flex', alignItems: 'center' }}

                            >
                                <span style={{ color: 'white', fontWeight: 'medium', fontSize: '12px' }}>
                                    {selectedDurationLabel}
                                </span>
                                <button
                                    onClick={handleDurationRemove}
                                    style={{ marginLeft: 8, cursor: 'pointer', border: 'none', background: 'transparent', color: 'white' }}
                                >
                                    <svg width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </Button>
                        </div>
                    )}
                    {selectedGuest && (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            
                            <Button
                                sx={{
                                    bgcolor: '#fcb632',
                                    color: 'white',
                                    '&:hover': {
                                        bgcolor: '#fcb632',
                                    },
                                    '&:active': {
                                        bgcolor: '#fcb632',
                                    }
                                }}
                                style={{ display: 'flex', alignItems: 'center' }}
                                
                            >
                                <span style={{ color: 'white', fontWeight: 'medium', fontSize: '12px' }}>
                                    {selectedGuest.title}
                                </span>
                                <button
                                    onClick={handleGuestRemove}
                                    style={{ marginLeft: 8, cursor: 'pointer', border: 'none', background: 'transparent', color: 'white' }}
                                >
                                    <svg width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </Button>
                        </div>
                    )}
                    {selectedCategoriesNames.length > 0 && (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>

                            <Button
                                sx={{
                                    bgcolor: '#fcb632',
                                    color: 'white',
                                    '&:hover': {
                                        bgcolor: '#fcb632',
                                    },
                                    '&:active': {
                                        bgcolor: '#fcb632',
                                    }
                                }}
                                style={{ marginLeft: 8 }}
                            >
                                <span style={{ color: 'white', fontWeight: 'medium', fontSize: '12px' }}>{selectedCategoriesNames}</span>
                                <button
                                    onClick={handleCategoryRemove}
                                    style={{ marginLeft: 8, cursor: 'pointer', border: 'none', background: 'transparent', color: 'white' }}
                                >
                                    <svg width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>

                            </Button>
                        </div>
                    )}

                </Box>

                {/* Layout starts here */}
                <Grid container spacing={2} sx={{ marginTop: '-18px' }}>
                    {/* Top Tags */}
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                bgcolor: 'white',
                                border: 1,
                                borderColor: 'grey.300',
                                maxHeight: '430px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                padding: 2,
                                overflowY: 'auto',borderRadius: '8px',
                            }}
                        >
                            <Typography component="h2" variant="h6" sx={{
                                position: 'sticky',
                                width: '100%',
                                top: '-20px',
                                background: 'white',
                                zIndex: 100
                            }}>
                                Top Tags
                            </Typography>

                            {tags.map(tag => (
                                <FormControlLabel
                                    sx={{
                                        accentColor: selectedTags.includes(tag.id) ? '#fcb632' : undefined
                                    }}
                                    key={tag.id}
                                    control={
                                        <Checkbox
                                            checked={selectedTags.includes(tag.id)}
                                            onChange={(event) => handleCheckboxChange(event, tag.id)}

                                        />

                                    }
                                    label={tag.name}
                                />
                            ))
                            }

                        </Box>
                    </Grid>


                    <Grid item xs={4}>
                        <Grid container spacing={4}>
                            {/* Date published */}
                            <Grid item xs={6}>
                                <Box
                                    sx={{
                                        bgcolor: 'white',
                                        border: 1,
                                        borderColor: 'grey.300',
                                        height: '230px',
                                        width: '220px',borderRadius: '8px',
                                    }}
                                >
                                    <Typography>
                                        <h4 style={{ marginLeft: '10px', marginTop: '5px' }}>Date Published</h4>
                                    </Typography>
                                    {dates.map((date) => (
                                        <FormControlLabel
                                            sx={{ marginLeft: '4px', marginTop: '-5x' }}
                                            key={date.id}
                                            control={
                                                <Radio
                                                    checked={selectedDate === date.label}
                                                    onChange={() => handleCheckboxChangeDate(date.label)}
                                                />
                                            }
                                            label={date.label}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                            {/* Popularity */}
                            <Grid item xs={6}>
                                <Box
                                    sx={{
                                        bgcolor: 'white',
                                        border: 1,
                                        borderColor: 'grey.300',
                                        height: '230px',
                                        width: '220px',borderRadius: '8px',
                                    }}
                                >
                                    <Typography>
                                        <h4 style={{ marginLeft: '10px', marginTop: '5px' }}>Popularity</h4>
                                    </Typography>
                                    {popularityOptions.map((option) => (
                                        <FormControlLabel
                                            sx={{ marginLeft: '4px', marginTop: '-5x' }}
                                            key={option.id}
                                            control={
                                                <Radio
                                                    id={option.id.toString()}
                                                    checked={selectedOption === option.id.toString()}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={option.label}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                            {/* Duration */}
                            <Grid item xs={6}>
                                <Box
                                    sx={{
                                        bgcolor: 'white',
                                        border: 1,
                                        borderColor: 'grey.300',
                                        height: '200px',
                                        width: '220px',borderRadius: '8px',
                                    }}
                                >
                                    <Typography>
                                        <h4 style={{ marginLeft: '10px', marginTop: '5px' }}>Duration</h4>
                                    </Typography>
                                    {durations.map((duration) => (
                                        <FormControlLabel
                                            sx={{ marginLeft: '4px', marginTop: '-5x' }}
                                            key={duration.id}
                                            control={
                                                <Radio
                                                    checked={selectedDuration === duration.label}
                                                    onChange={() => handleCheckboxChangeDuration(duration.label)}
                                                />
                                            }
                                            label={duration.label}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                            {/* Sort By */}
                            <Grid item xs={6}>
                                <Box
                                    sx={{
                                        bgcolor: 'white',
                                        border: 1,
                                        borderColor: 'grey.300',
                                        height: '200px',
                                        width: '220px',borderRadius: '8px',
                                    }}
                                >
                                    <Typography>
                                        <h4 style={{ marginLeft: '10px', marginTop: '5px' }}>Sort By Guest</h4>
                                    </Typography>
                                    <Autocomplete
                                        disablePortal
                                        id="guest-autocomplete"
                                        options={guests}
                                        value={selectedGuest}
                                        getOptionLabel={(option) => option.title}
                                        sx={{ width: '90%', marginLeft: '8px' }}
                                        renderInput={(params) => <TextField {...params} label="Select Guest" />}
                                        onChange={(event, newValue) => {
                                            console.log('Selected guest:', newValue);
                                            setSelectedGuest(newValue);

                                        }}
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* Categories */}
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                bgcolor: 'white',
                                border: 1,
                                borderColor: 'grey.300',
                                maxHeight: '430px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                padding: 2,
                                marginLeft: '20px',
                                overflowY: 'auto',borderRadius: '8px',
                            }}
                        >
                            <Typography component="h2" variant="h6" sx={{
                                position: 'sticky',
                                width: '100%',
                                top: '-20px',
                                background: 'white',
                                zIndex: 100
                            }}>
                                Categories
                            </Typography>
                            {categories.map((category) => (
                                <FormControlLabel
                                    key={category.id}
                                    control={
                                        <Checkbox
                                            checked={selectedCategories[category.id] || false}
                                            onChange={(event) => handleCheckboxChangeCategory(event, category.id)}
                                        />
                                    }
                                    label={category.name}
                                />
                            ))}
                        </Box>
                    </Grid>
                </Grid>

                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    gap={2}
                    marginTop='10px'
                >
                    <ReusableButton text="CLEAR" variant='outlined' onClick={handleClearAll} />
                    <ReusableButton text="APPLY FILTERS" variant='contained' />
                </Box>
            </Box>
        </Modal>
    );
};

export default FilterModal;
