import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    Grid,
    Typography,
    Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../Components/Form/CustomInput";
import { resetPassword } from "../../Features/Services/resetPassword";
import { LocalImages } from "../../Utils/images";

const ResetPassword = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const formik = useFormik({
        initialValues: {
            newPassword: "",
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string()
                .required("New password is required")
                .min(6, "Password must be at least 6 characters"),
        }),
        onSubmit: (values) => {
            setLoading(true);
            resetPassword(token, values.newPassword,
                (response) => {
                    setMessage("Thank you! Your password has been reset!");
                    setLoading(false);
                    setTimeout(() => {
                        setMessage("");
                        formik.resetForm();
                        navigate('/');
                    }, 3000);
                },
                (error) => {
                    setMessage("Your password has been not reset!");
                    setLoading(false);
                }
            );
        },
    });

    return (
        <Box maxWidth={true} style={{ height: "100vh" }}>
            <Card style={{ padding: "4vh", display: "flex", justifyContent: "center" }}>
                <img
                    src={LocalImages.logo}
                    style={{ width: "180px", position: "absolute", top: "-20px" }}
                    onClick={() => navigate('/')}
                />
            </Card>
            <Grid container
                style={{
                    height: "100%",
                    background: `url(${LocalImages.bgURL}) 0 0/100% 100% no-repeat`,
                    backgroundPosition: "center bottom",
                    backgroundSize: "cover",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <Grid item xs={10} sm={6} md={10} lg={4} xl={4} sx={{ mt: 15 }}>
                    <Card className="login-card">
                        <Typography variant="h3" component={"h3"} className="font-gloucester title-style01">
                            Reset Password
                        </Typography>
                        <CardContent style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
                            <form onSubmit={formik.handleSubmit}>
                                <CustomInput
                                    value={formik.values.newPassword}
                                    onChangeText={(value) => formik.setFieldValue("newPassword", value)}
                                    handleBlur={formik.handleBlur("newPassword")}
                                    isError={formik.errors.newPassword && formik.touched.newPassword}
                                    errorMsg={formik.errors.password}
                                    isRequired={true}
                                    label={"New Password"}
                                    type="password"
                                    placeholder="New Password"
                                    prefixIcon={
                                        <img
                                            src={LocalImages.lock}
                                            style={{ width: "60%", height: "auto" }}
                                        />
                                    }
                                    suffixIcon={(toggleSuffixIcon) => (
                                        <img
                                            src={LocalImages.eyeClose}
                                            style={{ width: "50%", height: "auto", float: "right" }}
                                        />
                                    )}
                                />
                                <Button
                                    type="submit"
                                    disabled={!formik.isValid || isLoading}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: "1rem", width: "100%" }}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : "Reset Password"}
                                </Button>
                                {message && (
                                    <Typography color={message.includes("Thank you") ? "green" : "red"} style={{ marginTop: "1rem", textAlign: "center" }}>
                                        {message}
                                    </Typography>
                                )}
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );


};

export default ResetPassword;
