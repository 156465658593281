const env = process.env;

const BASE_URL = env.REACT_APP_BASE_URL;
const VIDEO_BASE_URL = env.REACT_APP_VIDEO_BASE_URL;
const AWS_REGION = env.REACT_APP_AWS_REGION;
const AWS_ACCESS_KEY_ID = env.REACT_APP_AWS_ACCESS_KEY_ID;
const AWS_SECRET_ACCESS_KEY = env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const AWS_S3_BUCKET_NAME = env.REACT_APP_AWS_S3_BUCKET_NAME;

export const SECRETS = {
  BASE_URL,
  VIDEO_BASE_URL,
  AWS_REGION,
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_S3_BUCKET_NAME
};
