import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  CircularProgress,
  Typography,
  Backdrop,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { useVideoCategories } from "../../Hooks/useVideoCategories";
import { useVideoCollections } from "../../Hooks/useVideoCollections";
import { wildSearchVideos } from "../../Features/Services/Videos";
import { useNavigate } from "react-router-dom";
import ReusableButton from "../../ui/ReuseableButton";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import InnerHeader from "../../Components/InnerHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    getCategories,
    categoryList,
    isCategoryFetching,
    categoryFetchErrorMsg,
  } = useVideoCategories();

  const {
    getCollections,
    collectionList,
    isCollectionFetching,
    collectionFetchErrorMsg,
  } = useVideoCollections();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getCategories();
    getCollections();
  }, []);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFieldInitial = {
    collectionName: "",
    categoryName: "",
    newTitle: "",
  };
  const [searchField, setSearchField] = useState(searchFieldInitial);
  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    searchVideo();
  }, []);

  const searchVideo = () => {
    setSearching(true);
    wildSearchVideos(searchField)
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setRows([]);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const resetSearch = () => {
    setSearchField(searchFieldInitial);
    setRows([]);
  };

  const editVideo = (item) => {
    navigate("/editVideo", {
      state: { video_id: item.video_id },
    });
  };

  const menuItems = [
    { label: "Account Overview", path: "#" },
    { label: "Notifications", path: "#"},
    { label: "Videos", path: "#"},
    { label: "Collections", path: "#"},
    { label: "Schedule", path: "#" },
    { label: "Profile Settings", path: "#" },
  ];

  return (
<>
    
    <MainHeader />
    <InnerHeader />
    <Container className="button-wrapper-main" disableGutters maxWidth="lg" sx={{p: 1, mt: "5%"}}>
      <div className="page-url-wrapper">
      <ul className="ul-content">
      {menuItems.map((item) => (
        <li key={item.label}>
          <Link
            to={item.path}
            target="_blank"
            rel="noopener noreferrer"
            className={`li-content ${location.pathname === item.path ? "active-link" : ""}`}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
      </div>
      <Grid container spacing={2} alignItems="center" mb={2} className="button-content">
        <Grid item xs={12} sm={6} md={3}>
          <TextField label="Search" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Category"
            fullWidth
            value={searchField.categoryName}
            onChange={(e) => {
              setSearchField({
                ...searchField,
                categoryName: e.target.value,
              });
            }}
          >
            {categoryList.map((item) => (
              <MenuItem key={item?.category_id} value={item?.category_name}>
                {item?.category_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Collection"
            fullWidth
            value={searchField.collectionName}
            onChange={(e) => {
              setSearchField({
                ...searchField,
                collectionName: e.target.value,
              });
            }}
          >
            {collectionList?.map((item) => (
              <MenuItem key={item?.collection_id} value={item?.collection_name}>
                {item?.collection_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>


        <Grid className="reset-button-wrapper-main" item spacing={1} xs={12} sm={6} md={3} display="flex" justifyContent="flex-start" alignItems="center" sx={{gap: 1}}>

          
          <ReusableButton text="Search" variant="outlined" onClick={searchVideo} />

          <ReusableButton text="Reset" onClick={resetSearch} />



          {/* <Button onClick={resetSearch} variant="contained">
            Reset
          </Button> */}


        </Grid>


      </Grid>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>

          <TableHead sx={{backgroundColor: "#19315c"}}>
            <TableRow>
              <TableCell sx={{color:"white"}}>S No</TableCell>
              <TableCell sx={{color:"white"}}>Title</TableCell>
              <TableCell sx={{color:"white"}}>Category</TableCell>
              <TableCell sx={{color:"white"}}>Collection</TableCell>
              <TableCell sx={{color:"white"}}>Views</TableCell>
              <TableCell sx={{color:"white"}}>Actions</TableCell>
            </TableRow>
          </TableHead>


          <TableBody>
            {rows.length > 0
              ? rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row.video_id}>
                      <TableCell>{index + 1}.</TableCell>
                      <TableCell>{row.new_title}</TableCell>
                      <TableCell>{row.category_name}</TableCell>
                      <TableCell>{row.collection_name}</TableCell>
                      <TableCell>{row.views}</TableCell>
                      <TableCell
                        sx={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          editVideo(row);
                        }}
                      >
                 <EditIcon style={{ color: '#fbac18',}} />

                      </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
        {rows.length === 0 && !isSearching ? (
          <Typography
            textAlign={"center"}
            sx={{
              padding: "16px",
              display: "block",
              width: "100%",
            }}
          >
            No Records Found
          </Typography>
        ) : null}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Backdrop
        open={isSearching}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={"48px"} />
      </Backdrop>
    </Container>

    <FixedBottomNavigation />

    <Footer />
    </>
  );
};

export default Admin;
