import axios from "axios";

const customerFetch = async () => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://michaelkjaco-streaming-node-app.onrender.com/api/customers',
    headers: {}
  };
  
  try {
    const response = await axios.request(config);
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching video count:', error);
    throw error; 
  }
};
export {
    customerFetch
};
