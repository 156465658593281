import React from "react";
import { Card, Typography, CardContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomVideoPlayer = ({ handleVideoClose, videoUrl }) => {
  return (
    <Card sx={{ padding: "12px" }}>
      <Typography
        sx={{
          textAlign: "center",
          textDecoration: "underline",
          margin: "16px 0px",
        }}
      >
        {videoUrl?.title}
      </Typography>
      <IconButton
        sx={{
          position: "absolute",
          backgroundColor: "#fff",
          right: "-20px",
          top: "-20px",
        }}
        onClick={handleVideoClose}
      >
        <CloseIcon />
      </IconButton>

      <CardContent>
        <video
          style={{ width: "100%", height: "100%" }}
          autoPlay={true}
          controls
        >
          <source src={videoUrl?.url} type="video/mp4" />
        </video>
      </CardContent>
    </Card>
  );
};

export default CustomVideoPlayer;
