import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { useVideoHooks } from "../../Hooks/useVideoHooks";
import { LocalImages } from "../../Utils/images";

const LatestVideoAccount = () => {
  const navigate = useNavigate();

  const {
    getLatestVideos,
    latestVideos,
  } = useVideoHooks();

  useEffect(() => {
    getLatestVideos(1, 9);
  }, []);

  const editVideo = (item) => {
    navigate("/editVideo", {
      state: { video_id: item.video_id },
    });
  };

  const handleViewAll = () => {
    navigate("/latestVideos"); // Redirect to the latestVideos route
  };

  return (
    <Container maxWidth="xl" sx={{ mt: "3%" }}>
      <div className="latest-video-header">
        <h1 className="latest-video-title">Latest Videos</h1>
        <button className="view-all-button" onClick={handleViewAll}>
          View All
        </button>
      </div>
      <Grid container spacing={2}>
        {latestVideos.list.length > 0
          ? latestVideos.list.slice(0, 3).map((item, index) => (
              <div className="video-listing" key={index}>
                <div className="custom-video-logo">
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={LocalImages.videoCoverImg}
                      style={{
                        width: "107px",
                        height: "70px",
                        objectFit: "cover",
                      }}
                      alt="videoCoverImg"
                    />
                  </Box>
                </div>
                <div className="video-title">
                  <span className="video-heading" title={item?.new_title}>
                    {item?.new_title}
                  </span>
                  <span className="video-heading-view" title={item?.views}>
                    {item?.views} views
                  </span>
                </div>
                <div className="video-edit">
                  <Box
                    className="video-edit-icon"
                    sx={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      editVideo(item); // Pass the item to the function
                    }}
                  >
                    <EditIcon style={{ color: '#fbac18',}} />
                  </Box>
                </div>
              </div>
            ))
          : null}
      </Grid>
    </Container>
  );
};

export default LatestVideoAccount;
