import React, { useContext, useState, useRef, useEffect } from 'react';
import { Container, Button, Box, Typography } from '@mui/material';
import { useStreamStatus } from '../../StreamStatusContext';
import io from 'socket.io-client';
import { useLocation } from "react-router-dom";
import { livechatdata } from '../../Features/Services/liveChat';
import { generateVideoUrl, randomImage } from "../../Utils/videoSupport";
import { isValidString } from "../../Utils/validators";
import Webcam from 'react-webcam';
import { LocalImages } from "../../Utils/images";
import './AddLivechat.css';

const socket = io("https://iwc-tv.michaelkjaco.com:3000", {
  transports: ["websocket", "polling"],
  secure: true
});

const AddLiveChat = ({ room, userName }) => {
  const [videoInfo, setVideoInfo] = useState({
    id: "",
    title: "",
    url: "",
    release_date: "",
    description: "",
  })
  const { state, pathname } = useLocation();
  const { isLiveEnded } = useStreamStatus();
  const [isLoading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]); // To store all messages
  const [input, setInput] = useState('');
  const [messagejson, setMessageJSON] = useState([]);
  const [username, setUsername] = useState('');
  const [userid, setUserid] = useState('');
  const [videoid, setVideoid] = useState('');
  const messagesEndRef = useRef(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const webcamRef = useRef(null);
  const formatDateTime = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year}_${hours}:${minutes}`;
  };
  const currentDate = new Date();
  const formattedDateTime = formatDateTime(currentDate);

  useEffect(() => {
    // const videoid = `s3://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}/Live Stream Recording ${formattedDateTime}.webm`;
    // const videoid = state?.id;
    setVideoid(videoid);
    const storedUsername = localStorage.getItem('userInfo');
    const userName = JSON.parse(storedUsername);
    const userID = JSON.parse(storedUsername);
    if (storedUsername) {
      setUsername(userName.username);
      setUserid(userID.id);
    }


    // Load messages from local storage when component mounts
    // const storedMessages = localStorage.getItem('messages');
    // if (storedMessages) {
    //     setMessages(JSON.parse(storedMessages));
    // }

    const storedMessages = localStorage.getItem('messages');
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }

    socket.emit('joinRoom', room);

    // const handleMessage = (message) => {
    //     console.log('message ==>',message);
    //     setMessages((prevMessages) => {
    //         const updatedMessages = [...prevMessages, message];
    //         // localStorage.setItem('messages', JSON.stringify(updatedMessages)); 
    //         return updatedMessages;
    //     });
    // };

    const handleMessage = (message) => {
      // Avoid adding duplicate messages
      setMessages((prevMessages) => {
        if (prevMessages.some(msg => msg.text === message.text && msg.user === message.user)) {
          localStorage.setItem('messages', JSON.stringify(prevMessages));
          return prevMessages;
        }
        const updatedMessages = [...prevMessages, message];
        localStorage.setItem('messages', JSON.stringify(updatedMessages)); // Save updated messages to localStorage
        return updatedMessages;
        // return [...prevMessages, message];
      });
    };

    socket.on('message', handleMessage);

    return () => {
      socket.off('message', handleMessage);
      localStorage.removeItem('messages');
    };
  }, [room]);



  // Fetch LiveChat using videoId
  
  useEffect(() => {
    if (videoid) {
      console.log('AAa Data ===>', videoid);

      fetchLiveChat(videoid);
    }
  }, [videoid]);
  const fetchLiveChat = (videoid) => {
    // setLoading(true);
    fetch(
      `http://localhost:3001/api/getLivechatById?video_id=${videoid}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(' Data ===>', data);
        const transformedData = data?.data.map(item => ({
          ...item,
          message: JSON.parse(item.message),
          start_date: formatDateTime(new Date(item.start_date)) // Parse the message string to an object
      }));
      console.log('transformedData ==>',transformedData);
      
        if (data.status === "success" && data.data.length > 0) {
          console.log("data.videoDetails--", data.data[0]);
          const messageData = data.data.map(item => JSON.parse(item.message)); // Parse each message
          setMessages(messageData);
          // generateUrl(data.data[0]);
        } else {
          // setLoading(false);
          // alert("Failed to fetch video details");
        }
      })
      .catch((error) => {
        console.error("Error fetching video details:", error);
        // setLoading(false);
      });
  };


  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);


  useEffect(() => {
    const videoIdFromUrl = pathname.split("/").pop();
    if (videoIdFromUrl) {
      fetchVideoDetails(videoIdFromUrl);
    }
  }, [state, pathname]);
  const fetchVideoDetails = (videoId) => {
    setLoading(true);
    fetch(
      `https://michaelkjaco-streaming-node-app.onrender.com/api/video/getVideoDetailsById?video_id=${videoId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && data.data.length > 0) {
          console.log("data.videoDetails--", data.data[0]);
          generateUrl(data.data[0]);
        } else {
          setLoading(false);
          alert("Failed to fetch video details");
        }
      })
      .catch((error) => {
        console.error("Error fetching video details:", error);
        setLoading(false);
      });
  };
  const generateUrl = (item) => {
    console.log("TTTTTTT", item);
    setVideoid(item?.s3_video_id);
    if (isValidString(item?.s3_video_id)) {
      setLoading(true);
      generateVideoUrl(item?.s3_video_id)
        .then((url) => {
          setVideoInfo({
            id: item?.video_id,
            title: item?.new_title,
            url,
            release_date: item?.release_date,
            description: item?.synopsis,
          });
        })
        .catch((error) => {
          console.log("error => ", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      alert("Not a valid video");
    }
  };

  const sendMessage = async () => {
    // setTimeout(() => {
    //     const liveMessage = localStorage.getItem('messages');
    //     console.log("liveMessage", liveMessage);

    //     if (liveMessage) {
    //         const livemessagejson = JSON.parse(liveMessage);
    //         setMessageJSON(livemessagejson);
    //     } else {
    //         console.log("No message found in localStorage.");
    //     }
    // }, 5000);
    if (input.trim() && username.trim()) {
      const message = { text: input, 
        user: username,
        start_date: new Date().toLocaleString('en-US', { 
          weekday: 'short',  
          hour: 'numeric', 
          minute: 'numeric',
          hour12: true      
        }) };

      // Emit the message to the socket
      socket.emit('message', message);
      console.log("messages---", messages);

      // Update messages state locally
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, message];
        localStorage.setItem('messages', JSON.stringify(updatedMessages));
        return updatedMessages;
      });
      const messagePayload = {
        video_id: videoid,
        CustID: userid,
        message: message,
        status: true

      }
      console.log("YTY", messagePayload);

      try {
        const messageResponse = await livechatdata(messagePayload);

        console.log("Save message", messageResponse);
      } catch (error) {
        console.error('Error sending message data to API', error);
      }

      setInput('');

    }
  };

    const toggleWebcam = () => {
        setShowWebcam(!showWebcam);
    };

  return (
    <div className="chat-parent-container add-livechat">
      <div className="chat-container">
        <div className="chat-header">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" viewBox="0 0 24 17" fill="none">
            <path d="M16.2653 2.20801V7.28701H3.56531V9.82701H16.2653V14.908L23.8843 8.48301L16.2653 2.20801Z" fill="white" />
            <path d="M3.81732 0.114014H0.325317V16.7H3.81732V0.114014Z" fill="white" />
          </svg>
          <Typography variant="h5" gutterBottom className='livechat-heading'>
            Live Chat
          </Typography>
        </div>
        <div className="chat-body">
                    <div className="messages">
                        {messages.map((msg, index) => (
                            <div key={index} className="message">
                                <Box className="message-img" display="flex" justifyContent="center" alignItems="center">
                                    <img
                                        src={LocalImages.michaelJaco}
                                        style={{
                                            width: "26px",
                                            height: "26px",
                                            objectFit: "contain",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Box>
                                <div className="date-header">{msg.start_date}</div>
                                <div className="message-wrapper">
                                <div className='message-username'><strong>{msg.user}</strong></div>
                                <div className="message-date">{msg.start_date}</div>
                                </div>
                                <span className='user-message'>{msg.text}</span>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    {showWebcam && (
                        <div className="webcam-container">
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                className="webcam-view"
                            />
                        </div>
                    )}
                    <div className="chat-input">
                        <div className="chat-camera" onClick={toggleWebcam}>
                            <Box sx={{ position: "relative", marginRight: "10px" }}>
                                <img
                                    src={LocalImages.vector}
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                    }}
                                    alt="Upload Icon"
                                />
                            </Box>
                        </div>
                        <div className='chat-input-wrapper'>
                            <input
                                type="text"
                                placeholder="Enter your message"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                className="input-field"
                            />
                        </div>
                        <div onClick={sendMessage} className="send-button">

                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                                <path d="M20.0023 41C31.048 41 40.0023 31.8218 40.0023 20.5C40.0023 9.17816 31.048 0 20.0023 0C8.95662 0 0.00231934 9.17816 0.00231934 20.5C0.00231934 31.8218 8.95662 41 20.0023 41Z" fill="#18315C" />
                                <path d="M18.0735 33.4261L32.1109 14.6556L8.83697 17.428L12.4203 23.6545L26.7779 17.7346L14.4728 27.2096L18.0735 33.4261Z" fill="#FBFBFB" />
                            </svg>
                        </div>
                        <div className='plus-file-upload'>
                        <Box sx={{ position: "relative", marginRight: "10px" }}>
                          <img
                            src={LocalImages.plusSVG}
                            alt="plus Icon"
                            style={{
                              width: "auto",
                              height: "auto",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                        </div>
                    </div>
                </div>
      </div>
    </div>
  );
};

export default AddLiveChat;