import React from "react";
import Box from "@mui/material/Box";
import { Parallax } from "react-parallax";
import { Container, Grid } from "@mui/material";
import { LocalImages } from "../../Utils/images";
import { SideSocialLinks } from "../../Components/SideSocialLinks";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";

const TemplateScreen = () => {
  return (
    <>
      <MainHeader />
      <Box>
        <Box className="top-layout-header-bg">
          <Box className="top-layout-header-bg-curve"></Box>
        </Box>
        <Container>
          <Box className="layout-content"></Box>
          <Box className="layout-content"></Box>
        </Container>
      </Box>
      <Box
        sx={{
          width: "100%",
          justifyContent: "center",
          minHeight: "750px",
          boxSizing: "borderBox",
          paddingTop: "200px",
        }}
        className="faq-bg transform-style-bg"
      >
        <Container className="transform-style-content">
          <Box sx={{ pb: 2 }}>
            <img src={LocalImages.footerLogo} alt="Logo" />
          </Box>
          <Box sx={{ borderTop: "1px solid #fff", pt: 2 }}>
            <Grid container>
              <Grid xs={6} className="footer-links">
                <ul>
                  <li>
                    <a href="#">Contact</a>
                  </li>
                  <li>
                    <a href="#">Privacy</a>
                  </li>
                  <li>
                    <a href="#">Acceptable Use Policy</a>
                  </li>
                </ul>
              </Grid>
              <Grid
                xs={6}
                sx={{
                  justifyContent: "flex-end",
                  display: "flex",
                  color: "#fff",
                }}
              >
                Copyright © 2024 Michael Jaco. All Rights Reserved
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <SideSocialLinks />
      <FixedBottomNavigation />
      <Footer />
    </>
  );
};

export default TemplateScreen;
