import React, { useEffect, useState } from "react";
import {
  Container,
  CircularProgress,
  Backdrop,
  Typography,
  CardMedia,
  Box,
  Menu,
  MenuItem,
  Dialog,
} from "@mui/material";
import { LocalImages } from "../../Utils/images";
import { collectionlistVideo, collectionDelete } from "../../Features/Services/collectionVideo";
import EditCollection from "./EditCollection";
import AddSelectedVideo from "./AddSelectedVideo";

const CollectionList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [popupAnchor, setPopupAnchor] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false); 

  useEffect(() => {
    const fetchVideos = async () => {
      setIsLoading(true);
      try {
        const data = await collectionlistVideo();
        setVideos(data); 
      } catch (error) {
        console.error("Error fetching video data:", error);
        setVideos([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const handlePopupOpen = (event, video) => {
    setPopupAnchor(event.currentTarget);
    setSelectedVideo(video);
  };

  const handlePopupClose = () => {
    setPopupAnchor(null);
  };

  const handleEditClick = () => {
    setIsEditOpen(true); 
    handlePopupClose();
  };

  const handleDeleteClick = async (collectionID) => {
    if (!collectionID) return; 
    try {
      setIsLoading(true);
      await collectionDelete(collectionID);
      setVideos((prevVideos) => prevVideos.filter((video) => video.id !== collectionID));
      handlePopupClose();
    } catch (error) {
      console.error("Error deleting collection:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleEditClose = () => {
    setIsEditOpen(false);
  };
  const handleAddClick = () => {
    setIsAddOpen(true);  
    handlePopupClose();
  };

  const handleAddClose = () => {
    setIsAddOpen(false); 
  };

  const handleSave = (selectedVideos) => {
    console.log("Selected videos:", selectedVideos);
    
  };
  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <div className="collection-list-show-main">
        {videos.length > 0 ? (
          videos.map((video) => (
            <div className="collection-list-card" key={video.id}>
              <CardMedia
                component="img"
                image={`data:image/png;base64,${video.image}`}
                alt={video.title}
              />
              <div className="group-icon-wrapper">
                <div className="collection-title">{video.title}</div>
                <div className="group-icon">
                  <Box sx={{ position: "relative", marginRight: "10px" }}>
                    <img
                      src={LocalImages.groupIcon}
                      style={{
                        width: "auto",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      alt="Upload Icon"
                      onClick={(event) => handlePopupOpen(event, video)}
                    />
                  </Box>
                </div>
              </div>
              <div className="video-count-show">
                {video.video_id && Array.isArray(video.video_id) ? video.video_id.length : 0} videos
                </div>
            </div>
          ))
        ) : (
          <Typography variant="h6" align="center" sx={{ width: "100%" }}>
            No videos available
          </Typography>
        )}

        {/* Popup Menu */}
        <Menu
          anchorEl={popupAnchor}
          open={Boolean(popupAnchor)}
          onClose={handlePopupClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
          <MenuItem onClick={handleAddClick}>Add Video</MenuItem>
          <MenuItem onClick={() => handleDeleteClick(selectedVideo.id)}>Remove</MenuItem>
        </Menu>

        <Dialog open={isAddOpen} onClose={handleAddClose} fullWidth maxWidth="sm" className="add-selected-video">
          <AddSelectedVideo onSave={handleSave}  onClose={handleAddClose} /> 
        </Dialog>

        {/* EditCollection Dialog */}
        <Dialog open={isEditOpen} onClose={handleEditClose} fullWidth maxWidth="sm">
          <EditCollection video={selectedVideo} onClose={handleEditClose} />
        </Dialog>
      </div>

      {/* Loading Indicator */}
      <Backdrop
        open={isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={48} />
      </Backdrop>
    </Container>
  );
};

export default CollectionList;
