import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ReusableButton from '../../ui/ReuseableButton';
import { Box, Container, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
import LatestShowsHeader from '../../Components/LatestShowsHeader';
import { IndeterminateCheckBoxOutlined } from '@mui/icons-material';


function CustomPhoneIcon() {
    return (
        <div className="iconContainer">
            <PhoneIcon className="phoneIcon" />
        </div>
    );
}
function CustomAvatarIcon() {
    return (
        <div className="iconContainer">
            <Avatar className="phoneIcon" sx={{ background: "#FFA900", width: 25, height: 25 }} />
        </div>
    );
}
function CustomEmailIcon() {
    return (
        <div className="iconContainer">
            <EmailIcon className="phoneIcon" />
        </div>
    )
}

const inquiryOptions = [
    "Technical Support - For issues related to streaming quality, connectivity, or platform functionality.",
    "Billing and Payments - For questions regarding membership fees, payment methods, or billing discrepancies.",
    "Account Management - For assistance with account setup, profile updates, password resets, or account cancellation.",
    "Content Inquiries - For questions about available streaming content, content requests, or content-related complaints.",
    "Membership Plans - For information on different membership tiers, benefits, and plan upgrades or downgrades.",
    "Feedback and Suggestions - For user feedback on the platform's features, user experience, or suggestions for improvement.",
    "Legal and Compliance - For questions related to terms of service, privacy policy, copyright issues, or legal concerns.",
    "Accessibility - For assistance with accessibility features or accommodations for users with disabilities.",
    "Community and Social Media - For questions or support related to the platform's online community, social media channels, or events."
];

const EmailContact = () => {

    const form = useRef();
    const [selectedValue, setSelectedValue] = useState('');

    const [sendSuccessfully, setSendSuccessfully] = useState(false)
    const [sendError, setSendError] = useState(false)

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);

    }



    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_bv1kwvn', 'template_7bgw1x7', form.current, {
                publicKey: 'vviOhb7MfAzHWEWGp',
            })
            .then(
                () => {
                    setSendSuccessfully(true)
                    form.current.reset();
                    setTimeout(() => {
                        setSendSuccessfully(false)
                    }, 5000)
                    console.log('SUCCESS!');
                },
                (error) => {
                    setSendError(true)
                    setTimeout(() => {
                        setSendError(false)
                    }, 5000);
                    console.log('FAILED...', error.text);
                },
            );
    };


    return (


        <Grid xs={12} md={8} className="border-box-style01" sx={{ p: 1 }}>
            <LatestShowsHeader
                title="Get In Touch"
                desc={"To learn more about how our team can help you, contact us."}
            />
            <Container>
                <Box className="getinTouch-form-container">
                    <Typography variant="h6" gutterBottom>
                        How can we help you?
                    </Typography>

                    <form ref={form} onSubmit={sendEmail}>

                        <Box className="getinTouch-field">
                            <Typography variant="subtitle1">Type of Inquiry</Typography>

                            <Select name="type_of_inquiry" value={selectedValue} fullWidth onChange={handleSelectChange}>

                                {inquiryOptions.map((option, index) => (
                                    <MenuItem key={index} value={option} style={{ width: '100%'}}>
                                        {option}
                                    </MenuItem>
                                ))}

                            </Select>

                        </Box>

                        <Box className="getinTouch-field">
                            <Typography variant="subtitle1">Name *</Typography>
                            <TextField name="user_name" fullWidth placeholder="Name" InputProps={{
                                startAdornment: (
                                    <CustomAvatarIcon />
                                ),
                                sx: {
                                    "& input": {
                                        paddingLeft: "5px",
                                        marginLeft: "1px"
                                    },
                                },
                            }}
                                required
                            />
                        </Box>
                        <Box className="getinTouch-field">
                            <Typography variant="subtitle1">Phone</Typography>
                            <TextField name="user_phone" fullWidth placeholder="Phone" InputProps={{
                                startAdornment: <CustomPhoneIcon />,
                                sx: {
                                    "& input": {
                                        paddingLeft: "5px",
                                        marginLeft: "1px"
                                    },
                                },
                            }} />
                        </Box>
                        <Box className="getinTouch-field">
                            <Typography variant="subtitle1">Email</Typography>
                            <TextField name="user_email" fullWidth placeholder="Email" InputProps={{
                                startAdornment: <CustomEmailIcon />,
                                sx: {
                                    "& input": {
                                        paddingLeft: "5px",
                                        marginLeft: "1px"
                                    },
                                },
                            }}
                                required
                            />
                        </Box>
                        <Box className="getinTouch-field">
                            <Typography variant="subtitle1">Message</Typography>
                            <TextareaAutosize name="message" rowsMin={1} rowsMax={3} placeholder="Type your message here..." style={{ width: '100%', height: "140px"}}
                                required
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "start", my: 1 }}>
                            <ReusableButton type="submit" text="Send Message" style={{ width: '180px' }} />
                        </Box>

                        {sendSuccessfully && (
                            <Typography style={{ color: "#47a447" }}>

                                Email has been sent Successfully!

                            </Typography>

                        )}

                        {sendError && (
                            <Typography style={{ color: "#f70515" }}>

                                Something went wrong. Please try again later!

                            </Typography>

                        )}

                    </form>
                </Box>
            </Container>
        </Grid>

    )
}

export default EmailContact