import { Box, Typography } from "@mui/material";
import React from "react";
import { LocalImages } from "../../Utils/strings";

const LatestShowsHeader = ({ title, desc, image }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: "5px",
        pb: 5,
        pt: { xs: 2, md: 0 },
      }}
    >
      <Typography component={"h4"} variant="h4" className="font-gloucester">
        {title}
      </Typography>
      <Typography
        component={"body"}
        variant="subtitle1"
        className="font-notoSans color-gray"
      >
        {desc}
      </Typography>
      <img src={image} style={{ width: "120px" }} alt="" />
    </Box>
  );
};

export default LatestShowsHeader;

LatestShowsHeader.defaultProps = {
  image: LocalImages.leaveWings,
};
