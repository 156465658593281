import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import menuIcon from "../Assets/Images/icons/menuIcon.png";
import jacoProfile from "../Assets/Images/michael_jaco.png";
import DrawerNavigation from "./DrawerNavigation";
import bottomBg from "../Assets/Images/icons/bottomBg.png"
import { useNavigate } from "react-router-dom";

export default function FixedBottomNavigation() {
  
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  

  return (
    <Box sx={{ pb: 5 }}>
    
      <Box
    sx={{
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1200,
      display: { xs: "flex", sm: "none"},
      justifyContent: "center",
      backgroundImage: `url(${bottomBg})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      alignItems: "center",
      width: '100vw',
      height: "20vh",
    }}
    elevation={3}
  >
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: 'transparent',
        mt: '60px',
      }}
    >
      <BottomNavigationAction
        label="Menu"
        icon={
          <img
            onClick={toggleDrawer(true)}
            src={menuIcon}
            style={{ width: 24, height: 24 }}
            alt="Menu Icon"
          />
        }
      />
      <BottomNavigationAction
        label="Playlists"
        icon={<PlaylistAddIcon onClick={() => navigate("/savedVideos")} fontSize="large" sx={{ color: 'grey' }} />}
      />
      <BottomNavigationAction
        icon={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 56,
              height: 56,
              borderRadius: "50%",
              backgroundColor: "grey", 
              padding: 2, 
            }}
          >
            <LiveTvIcon onClick={()=> navigate('/liveStream')} sx={{ color: "black", height: 44, width: 44 }} />
          </Box>
        }
        sx={{ mb: 10 }}
      />
      <BottomNavigationAction
        label="Library"
        icon={<VideoLibraryIcon onClick={() => navigate("/videoGallery")} fontSize="large" sx={{ color: 'grey' }} />}
      />
      <BottomNavigationAction
        icon={
          <Avatar
          onClick={() => navigate("/admin")}
            alt="User"
            src={jacoProfile}
            sx={{ width: 40, height: 40, border: "2px solid #ffa900" }}
          />
        }
      />
    </BottomNavigation>
  </Box>

      <DrawerNavigation open={drawerOpen} onClose={toggleDrawer(false)} />

    </Box>
  );
}
