import React, { useEffect, useState } from "react";
import { Container, Box, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import InnerHeader from "../../Components/InnerHeader";
import FixedBottomNavigation from "../../Components/FixedBottomNavigation";
import { videoCount } from "../../Features/Services/Videos";
import LatestVideoAccount from "../Admin/latestVideoAccount";
import { customerFetch } from "../../Features/Services/customer";
import { LocalImages } from "../../Utils/images";
import './account.css';

const AccountOverview = () => {
  const location = useLocation();
  const [totalVideos, setTotalVideos] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideoCount = async () => {
      try {
        const data = await videoCount(); // Call the service function
        setTotalVideos(data.total_videos); // Update state with the total count
      } catch (error) {
        console.error('Failed to fetch video count:', error); // Handle any errors
      }
    };

    fetchVideoCount(); // Invoke the function
  }, []);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const data = await customerFetch();
        setTotalCustomers(data);
      } catch (error) {
        console.error('Failed to fetch customers:', error);
      }
    };

    fetchCustomer();
  }, []);

  console.log("totalCustomers", totalCustomers);

  const menuItems = [
    { label: "Account Overview", path: "/account-overview" },
    { label: "Notifications", path: "/send-notification" },
    { label: "Videos", path: "/videoGallery" },
    { label: "Collections", path: "/collection" },
    { label: "Schedule", path: "/" },
    { label: "Profile Settings", path: "/profile" },
  ];

  return (
    <>

      <MainHeader />
      <InnerHeader />
      <Container className="button-wrapper-main" disableGutters maxWidth="lg" sx={{ p: 1, mt: "3%" }}>
        <div className="page-url-wrapper">
          <ul className="ul-content">
            {menuItems.map((item) => (
              <li key={item.label}>
                <Link
                  to={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`li-content ${location.pathname === item.path ? "active-link" : ""}`}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div class="total-revenue-wrapper">
          <div className="video-total-wrapper">
            <div className="video-total">
              <div className="total-number-wrapper">
                <span className="total-number">{totalVideos}</span>
              </div>
              <div className="total-number-text-wrapper">
                <span className="total-number-text">Total Videos</span>
              </div>
            </div>
            <div className="video-total">
              <div className="total-number-wrapper">
                <span className="total-number">850</span>
              </div>
              <div className="total-number-text-wrapper">
                <span className="total-number-text">Subscribers</span>
              </div>
            </div>
            <div className="video-total">
              <div className="total-number-wrapper">
                <span className="total-number">$850.25</span>
              </div>
              <div className="total-number-text-wrapper">
                <span className="total-number-text">Monthly Revenue</span>
              </div>
            </div>
            <div className="video-total">
              <div className="total-number-wrapper">
                <span className="total-number">$14,000.32</span>
              </div>
              <div className="total-number-text-wrapper">
                <span className="total-number-text">Total Revenue</span>
              </div>
            </div>
          </div>
          <div className="latest-video-wrapper">
            <div className="latest-video-main">
              <LatestVideoAccount />
            </div>
            <div className="customrt-main-list">
              <div className="customer-main-list-wrapper">
                <h1 className="latest-video-title">Newest Members</h1>
                <button className="view-all-button" >
                  View All
                </button>
              </div>
              <div className="fetch-customer-list">
                {totalCustomers && totalCustomers.length > 0 ? (
                  totalCustomers.slice(0, 9).map((customer) => (
                    <div
                      key={customer.CustID}
                      className="customer-list-item"
                    >
                      <Box sx={{ position: "relative", marginRight: "10px" }}>
  <img
    src={
      customer.profile_image_url
        ? `data:image/png;base64,${customer.profile_image_url}`
        : LocalImages.customerDummyImg
    }
    style={{
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      objectFit: "cover",
    }}
    alt={customer.UserName || "Customer Image"}
  />
</Box>

                      
                      <div>
                        <span className="customer-name"
                        >
                          {customer.UserName}
                        </span>

                      </div>
                    </div>
                  ))
                ) : (
                  <p>No customers found.</p>
                )}
              </div>
            </div>
          </div>

        </div>

      </Container>

      <FixedBottomNavigation />

      <Footer />
    </>
  );
};

export default AccountOverview;
