import { useState } from "react";
import { fetchCategories } from "../Features/Services/Videos";

export const useVideoCategories = () => {
  // search videos state handlers
  const [categoryList, setCategoryList] = useState([]);
  const [isCategoryFetching, setIsSearchVideosFetching] = useState(false);
  const [categoryFetchErrorMsg, setCategoryFetchErrorMsg] = useState("");

  const getCategories = async () => {
    setIsSearchVideosFetching(true);
    try {
      const { data } = await fetchCategories();
      setIsSearchVideosFetching(false);
      setCategoryFetchErrorMsg("");
      setCategoryList(data);
    } catch (error) {
      setIsSearchVideosFetching(false);
      setCategoryFetchErrorMsg(error?.message);
    }
  };

  return {
    getCategories,
    categoryList,
    isCategoryFetching,
    categoryFetchErrorMsg,
  };
};
