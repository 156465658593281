import React from 'react';
import { Box } from '@mui/system';

const ReusableButton = ({ text, style, onClick, variant, type }) => {
  const buttonClass = variant === 'outlined' ? 'button button-outlined' : 'button';

  return (
    <Box sx={{ justifyContent: "center", textAlign: "center" }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <button
          className={buttonClass}
          style={{ ...style }}
          onClick={onClick}
          type={type}
        >
          <span style={{ fontSize: "18px", fontWeight: "medium" }}>{text}</span>
        </button>
      </div>
    </Box>
  );
};

export default ReusableButton;
