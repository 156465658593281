import React from 'react';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Strings } from '../../Utils/strings';
import { LocalImages } from "../../Utils/strings";


const ImageFrame = ({ imageUrl, width, height, marginLeft }) => {
  return (
    <div style={{ width, height, marginLeft: marginLeft || "0px" }}>
      <img src={imageUrl} style={{ width: "100%", height: "100%" }} alt="michael images" />
    </div>
  );
};


const DiscoverWorld = () => {

  return (
    <Box className='discover-world-main' sx={{
      my: "20px"
    }}>
      <Box className='discover-world'
      sx={{ pb: {xs: '25%', md: '10%'} }}>

        <Grid container alignItems="center" justifyContent="center" sx={{width: "100%", display: "flex", flexDirection: {xs:"column-reverse", md:"row"}}}>

            <Grid item xs={12} md={6} xl={6} sx={{py: {sm:0, md:10}}} className=''>

              <Box xl="6" md="12" sm="12" xs="12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <ImageFrame
                    imageUrl={LocalImages.discoverImg01}
                    height="80%"
                    width="30%"
                  />
                  <ImageFrame imageUrl={LocalImages.discoverImg02} height="80%" width="40%" />
                </div>
                <div
                  style={{
                    marginTop: "-15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                    justifyContent: "center",
                  }}
                >
                  <ImageFrame imageUrl={LocalImages.discoverImg03} height="60%" width="35%" />
                  <ImageFrame
                    imageUrl={LocalImages.discoverImg04}
                    height="60%"
                    width="38%"
                    marginLeft="-3%"
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                // height: {md:"100vh"},
                pt: {xs:12, md:0},
                mx: 1,
              }}
              >
              <Typography
                component={"h4"}
                variant="h4"
                className="font-gloucester"
              >
                {Strings.discoverAWorldOfContent}
              </Typography>
              <Typography
                component={"body"}
                variant="subtitle1"
                className="font-notoSans color-gray"
                sx={{px: {xs: 2, sm: 15}, pb: 3, textAlign: "center", lineHeight: 1}}

              >
                {Strings.immerseYourself}
              </Typography>
              <img src={LocalImages.leaveWings} style={{ width: "120px" }} alt="" />
              </Box>
            </Grid>

        </Grid>

        
      </Box>
    </Box>
  )
}

export default DiscoverWorld
