import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import "./Discount_list.css";
import MainHeader from "../../Components/MainHeader";
import { LocalImages } from "../../Utils/images";

const DiscountListing = () => {
  const [discounts, setDiscounts] = useState([
    // Example discounts for demonstration
    {
      discountCode: "SUMMER2024",
      discountName: "percentage",
      discountPercentage: 15,
      usageLimit: 100,
      discountDescription: 'TEST',
      status: 'true',
      startDate: "2024-09-01",
      endDate: "2024-09-30",
    }
  ]);  // Static example discounts, you can update this with dynamic data from your API

  const navigate = useNavigate();

  const renderDiscountList = () => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Discount Code</TableCell>
          <TableCell>Discount Name</TableCell>
          <TableCell>Discount Percentage</TableCell>
          <TableCell>Usage Limit</TableCell>
          <TableCell>Discount Description</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {discounts.map((discount, index) => (
          <TableRow key={index}>
            <TableCell>{discount.discountCode}</TableCell>
            <TableCell>{discount.discountName}</TableCell>
            <TableCell>{discount.discountPercentage}</TableCell>
            <TableCell>{discount.usageLimit === 0 ? "Unlimited" : discount.usageLimit}</TableCell>
            <TableCell>{discount.discountDescription}</TableCell>
            <TableCell>{discount.status}</TableCell>
            <TableCell>{discount.startDate}</TableCell>
            <TableCell>{discount.endDate}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Box maxWidth={true} style={{ height: "100vh" }}>
      <MainHeader />
        <Grid item xs={12}>
          <Typography variant="h5" component={"h5"} className="discount-list-wrapper font-gloucester title-style02">
            Discount List
          </Typography>
          {renderDiscountList()}
        </Grid>
    </Box>
  );
};

export default DiscountListing;
