import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import { Screens } from "./screens";
import { AuthContext } from "../Context/AuthContext";
// import { StreamStatusProvider } from '../StreamStatusContext';
export default function AppRoutes() {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    // <StreamStatusProvider>
    <Routes>
      <Route path="/">
        <Route path="/" element={<Screens.Dashboard />} />
        {!isLoggedIn ? (
          <Route path="/login" element={<Screens.Login />} />
          
        ) : (
          <>
            <Route path="/videoGallery" element={<Screens.VideoGallery />} />
            <Route
              path="/videoStreaming/:id"
              element={<Screens.VideoStreaming />}
            />
            <Route path="/latestVideos/" element={<Screens.LatestVideos />} />
            <Route path="/iwcVideos/" element={<Screens.IWCVideos />} />
            <Route path="/searchPage/" element={<Screens.SearchPage />} />
            <Route path="/iwcSocial/" element={<Screens.IWCSocial />} />
            <Route path="/admin/" element={<Screens.Admin />} />
            <Route path="/profile" element={<Screens.Profile />} />
            <Route path="/account-overview" element={<Screens.AccountOverview />} />
            <Route path="/collection" element={<Screens.Collection />} />
            <Route path="/savedVideos/" element={<Screens.SavedVideos />} />
            
            <Route
              path="/recentlyWatched/"
              element={<Screens.RecentlyWatched />}
            />

            <Route
              path="/admin/livestream"
              element={
                <Screens.LiveSteam room={"room1"} userName={"Harmionee"} />
              }
            />
            <Route path="/admin/livechat" element={<Screens.LiveChat />} />
            {/* <Route path="/admin/livestream" element={<AWSKinesisPublisher />} /> */}
            <Route
              path="/livestream"
              element={<Screens.ReciverLiveStreaming />}
            />
            <Route
              path="/admin/send-notification"
              element={<Screens.Notification />}
            />
            <Route path="/add-Video-admin/" element={<Screens.AddVideoAdmin />} />
            <Route path="/editVideo/" element={<Screens.EditVideo />} />
            <Route path="/template/" element={<Screens.TemplateScreen />} />
            <Route path="/discount" element={<Screens.Discount />} />
            <Route path="/discount-list" element={<Screens.DiscountListing />} />
          </>
        )} 
        <Route path="/register" element={<Screens.Register />} />
        <Route path="/ourShows/" element={<Screens.OurShows />} />
        <Route path="/getInTouch/" element={<Screens.GetInTouch />} />
        <Route path="/forgotpassword/" element={<Screens.ForgotPassword />} />
        <Route path="/reset-password/:token" element={<Screens.ResetPassword />} />
        <Route path="*" element={<Screens.Dashboard />} />
        <Route path="/" element={<Screens.Dashboard />} />
      </Route>
    </Routes>
    // </StreamStatusProvider>
  );
}
