import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";

import { LocalImages } from "../Utils/strings";
import HomeIcon from '@mui/icons-material/Home';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import { Link, useNavigate } from 'react-router-dom';

export default function DrawerNavigation({ open, onClose }) {

    const navigate = useNavigate();

    const menuItems = [
        { text: "Home", icon: <HomeIcon />, path: "/" },
        {
            text: "Video Library",
            icon: <CollectionsOutlinedIcon />,
            path: "/videoGallery",
        },
        { text: "Latest Videos", icon: <VideocamIcon />, path: "/latestVideos" },
        {
            text: "IWC Only Videos",
            icon: <PermMediaOutlinedIcon />,
            path: null,
            onClick: () => {},
        },
        {
            text: "IWC Social",
            icon: <ViewComfyOutlinedIcon />,
            path: "https://michaelkjacosocial.com/choice/",
            pageTarget: "_blank",
        },
        {
            text: "Recently Watched",
            icon: <HistoryOutlinedIcon />,
            path: "/recentlyWatched",
        },
        {
            text: "Saved Videos",
            icon: <CollectionsBookmarkOutlinedIcon />,
            path: "/savedVideos",
        },
    ];

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={onClose}
            role="presentation"
            sx={{
                zIndex: 1300,
                '& .MuiDrawer-paper': {
                    position: 'relative',
                    width: '75vw',
                    borderRight: '7px solid #ffa900',
                },
                '--Drawer-transitionDuration': open ? '0.4s' : '0.2s',
                '--Drawer-transitionFunction': open
                  ? 'cubic-bezier(0.79,0.14,0.15,0.86)'
                  : 'cubic-bezier(0.77,0,0.18,1)',
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    pl: 1.5,
                    height: "100%",
                    position: "relative",
                }}
                role="presentation"
                onClick={onClose}
                onKeyDown={onClose}
            >
                <img
                    onClick={() => {
                        navigate("/");
                    }}
                    style={{
                        width: "120px",
                        height: "120px",
                        borderRadius: "50%",
                    }}
                    src={LocalImages.logo}
                    alt="Logo"
                />

                <List sx={{ flexGrow: 1 }}>
                    {menuItems.map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton
                                component={
                                    item.path ? (item.pageTarget ? "a" : Link) : "button"
                                }
                                href={item.path && item.pageTarget ? item.path : undefined}
                                to={item.path && !item.pageTarget ? item.path : undefined}
                                onClick={item.onClick}
                                target={item.pageTarget}
                                rel={item.pageTarget ? "noopener noreferrer" : undefined}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                <Divider />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Settings" />
                        </ListItemButton>
                    </ListItem>
                </List>

                {/* Additional line div */}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: '5px',
                        width: '3px',
                        height: '100%',
                        backgroundColor: '#ffa900', 
                        zIndex: 1,
                    }}
                />
            </Box>
        </Drawer>
    );
}
