import React from "react";
import { LocalImages } from "../../Utils/strings";

export const SideSocialLinks = () => {

  return (
    <div className="side-social-link-container">


      <a href="https://rumble.com/user/michaelj5326" target="_blank" rel="noopener noreferrer">
        <div
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <img
            src={LocalImages.rumbleImg}
            style={{ width: "100%", height: "100%" }}
            alt="logo"
          />
        </div>
      </a>

      <a href="https://gab.com/MichaelJaco" target="_blank" rel="noopener noreferrer">
        <LogoContainer
          imgUrl={LocalImages.cabLogo}
          imagStyle={{
            width: "100%",
            height: "100%",
          }}
        />
      </a>

      <a href="https://www.facebook.com/michaelkjaco" target="_blank" rel="noopener noreferrer">
        <LogoContainer bgColor="rgba(66, 103, 178, 1)" imgUrl={LocalImages.fbLogo} />
      </a>
      
      <div
        style={{
          width: "50px",
          height: "35px",
          display: "block",
          marginBottom: "12px"
        }}
      >
        <a href="https://www.instagram.com/unleashingintuition/" target="_blank" rel="noopener noreferrer">
          <LogoContainer imgUrl={LocalImages.instaLogo} imagStyle={{
            width: "100%",
            height: "100%",
          }} />
        </a>
      </div>


      <a href="https://twitter.com/MichaelJaco10" target="_blank" rel="noopener noreferrer">
        <div
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <img
            src={LocalImages.twitterLogo}
            style={{ width: "100%", height: "100%" }}
            alt="logo"
          />
        </div>
      </a>

    
      <a href="https://t.me/unleashingintuitionsecrets" target="_blank" rel="noopener noreferrer">
        <div
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <img
            src={LocalImages.telegramLogo}
            style={{ width: "100%", height: "100%" }}
            alt="logo"
          />
        </div>
      </a>


    </div>
  );
};

const LogoContainer = ({ bgColor, imgUrl, imagStyle }) => {
  return (
    <div
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50px",
        backgroundColor: bgColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "12px",
      }}
    >
      <img
        src={imgUrl}
        style={{ width: "25px", height: "25px", ...imagStyle }}
        alt="logo"
      />
    </div>
  );
};
