import "./App.css";
import AppRoutes from "./Navigations";
import { StreamStatusProvider } from "./StreamStatusContext";

function App() {
  return (
    <StreamStatusProvider>
      <AppRoutes />
    </StreamStatusProvider>
  )
}

export default App;
