import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, Checkbox, FormControlLabel, IconButton, Alert, CircularProgress } from '@mui/material';
import ReusableButton from '../../ui/ReuseableButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import MainHeader from '../../Components/MainHeader';
import FixedBottomNavigation from '../../Components/FixedBottomNavigation';

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_VIDEO_BASE_URL}notification/listNotification`);
        setNotifications(response.data.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        toast.error('Error fetching notifications: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleAddNew = async (values, { resetForm }) => {
    try {
      const payload = {
        notification: values.notification,
        notification_type: 'system',
        start_date: values.startDate,
        end_date: values.endDate,
        status: values.active ? 'ACTIVE' : 'ISACTIVE',        
      };

      const response = await axios.post(`${process.env.REACT_APP_VIDEO_BASE_URL}notification/addNotification`, payload);

      console.log('Success:', response.data);

      setNotifications([...notifications, payload]);
      resetForm();
      setShowForm(false);

    } catch (error) {
      console.error('Error:', error);
      toast.error('Error adding notification: ' + error.message);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const validationSchema = Yup.object().shape({
    notification: Yup.string().required('Notification is required'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date().required('End Date is required'),
    active: Yup.boolean().required('Active status is required')
  });

  return (
    <>
      <MainHeader />
      <ToastContainer />
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <h2>Manage Notifications</h2>
          <ReusableButton
            variant="contained"
            onClick={() => setShowForm(!showForm)}
            text={showForm ? 'Close Form' : 'Add New'}
          />
        </Box>
        {showForm && (
          <Formik
            initialValues={{ notification: '', startDate: '', endDate: '', active: true }}
            validationSchema={validationSchema}
            onSubmit={handleAddNew}
          >
            {({ errors, touched, handleChange, values }) => (
              <Form>
                <Box display="flex" alignItems="center" mb={2}>
                  <TextField
                    label="Notification"
                    name="notification"
                    value={values.notification}
                    onChange={handleChange}
                    error={touched.notification && Boolean(errors.notification)}
                    helperText={touched.notification && errors.notification}
                    sx={{ marginRight: 2, width: '400px' }}
                  />
                  <TextField
                    label="Start Date"
                    type="date"
                    name="startDate"
                    InputLabelProps={{ shrink: true }}
                    value={values.startDate}
                    onChange={handleChange}
                    error={touched.startDate && Boolean(errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    sx={{ marginRight: 2, width: '250px' }}
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    name="endDate"
                    InputLabelProps={{ shrink: true }}
                    value={values.endDate}
                    onChange={handleChange}
                    error={touched.endDate && Boolean(errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    sx={{ marginRight: 2, width: '250px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="active"
                        checked={values.active}
                        onChange={handleChange}
                      />
                    }
                    label="Active"
                  />
                  <ReusableButton text="Add New" type="submit" />
                </Box>
              </Form>
            )}
          </Formik>
        )}
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: "#19315c" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Notification</TableCell>
                  <TableCell sx={{ color: "white" }}>Start Date</TableCell>
                  <TableCell sx={{ color: "white" }}>End Date</TableCell>
                  <TableCell sx={{ color: "white" }}>Active</TableCell>
                  <TableCell sx={{ color: "white", textAlign: 'right', paddingRight: '35px' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{row.notification}</TableCell>
                    <TableCell>{formatDate(row.start_date)}</TableCell>
                    <TableCell>{formatDate(row.end_date)}</TableCell>
                    <TableCell>{row.status.toUpperCase()}</TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <IconButton
                          aria-label="edit"
                          sx={{ marginRight: 1 }}
                          disabled
                        >
                          <EditIcon sx={{ color: '#FFA903' }} />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          sx={{ color: 'red' }}
                          disabled
                        >
                          <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                      </div>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
      <FixedBottomNavigation />
    </>
  );
};

export default Notification;
