import axios from "axios";

const getUserIDFromLocalStorage = () => {
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
        const userInfo = JSON.parse(storedUserInfo);
        return userInfo.id;
    }
    return null;
};
/* Profile User Update API */
export const profileUser = async (payload, successCB, failureCB) => {
    const userID = getUserIDFromLocalStorage();

    const data = JSON.stringify({
        UserName: payload.UserName,
        FirstName: payload.FirstName,
        LastName: payload.LastName,
        PhoneNumber: payload.PhoneNumber,
        State: payload.State,
        Country: payload.Country,
        Address: payload.Address,
        City: payload.City,
        ZipCode: payload.ZipCode,
        profile_image_url: payload.profile_image_url,
    });
    const config = {
        method: 'PUT',
        maxBodyLength: Infinity,
        url: `https://michaelkjaco-streaming-node-app.onrender.com/api/customers/${userID}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
    };


    axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            if (successCB) successCB(response.data);
        })
        .catch((error) => {
            console.error("Registration error:", error);
            if (failureCB) failureCB(error);
        });
};

/* Profie Data Show */
export const profileUserData = async (data, successCB, failureCB) => {
    const userID = getUserIDFromLocalStorage();


    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        url: `https://michaelkjaco-streaming-node-app.onrender.com/api/customers/${userID}`,
        headers: {},
        data: data
    };

    axios
        .request(config)
        .then((response) => {
            console.log('API Response:', response); 
            if (response.data && response.data.length > 0) {
                successCB(response.data);
            } else {
                failureCB('No data returned from the API');
            }
        })
        .catch((error) => {
            console.error('Error fetching data:', error); 
            failureCB(error); 
        });
};

/* Profile User Password Change API */
export const updatePassword = (passwordPayload, successCB, failureCB) => {
    const data = JSON.stringify({
       email: passwordPayload.email,
       oldPassword: passwordPayload.oldPassword,
       newPassword: passwordPayload.newPassword,
       verifyNewPassword: passwordPayload.verifyNewPassword,
    });
    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://michaelkjaco-streaming-node-app.onrender.com/api/update-password',
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
    };


    axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            if (successCB) successCB(response.data);
        })
        .catch((error) => {
            console.error("password update error:", error);
            if (failureCB) failureCB(error);
        });
};


