import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext({});

const initialUserInfo = {
  first_name: "",
  last_name: "",
  membership_expire: "",
  membership_level: "",
  membership_valid: false,
  user_id: "",
  user_nicename: "",
};

const AuthProvider = ({ children }) => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [userInfo, setUserInfo] = useState(initialUserInfo);

  const [liveStreamStatus, setLiveStreamStatus] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    
    const userData = JSON.parse(localStorage.getItem("userInfo")) || {};
    
    if (Object.keys(userData).length > 0) {
      setUserInfo(userData);
      setIsLoggedIn(true)

      console.log('isLoggedIn--',isLoggedIn)
      console.log( 'userData--' ,userData)
    }
  }, []);

  const toggleLogin = () => {
    if (isLoggedIn) {
      localStorage.removeItem("userInfo");
    }
    setIsLoggedIn((prevValue) => !prevValue);
  };

  const updateUserInfo = (data) => {
    if (!isLoggedIn) {
      localStorage.setItem("userInfo", JSON.stringify(data));
    } else {
      localStorage.removeItem("userInfo");
    }
    setUserInfo(data);
    toggleLogin();
  };

  const toggleLiveStreamStatus = () => {
    setLiveStreamStatus((prevState) => !prevState);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        toggleLogin,
        userInfo,
        updateUserInfo,
        liveStreamStatus,
        toggleLiveStreamStatus,
        searchQuery,
        setSearchQuery,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
