import axios from "axios";



const getCollectionIDFromLocalStorage = () => {
  const storeCollectionInfo = localStorage.getItem("collectionVideoId");
  if (storeCollectionInfo) {
      const collectionInfo = JSON.parse(storeCollectionInfo);
      return collectionInfo.id;
  }
  return null;
};
const collectionID = getCollectionIDFromLocalStorage();

/* Collection Video API */
export const collectionVideo = async (collectionPayload, successCB, failureCB) => {

  const data = JSON.stringify({
    title: collectionPayload.title,
    des: collectionPayload.des,
    privacy: collectionPayload.privacy,
    image: collectionPayload.image,
    video_id: collectionPayload.video_id,
  });

  const config = {
    method: 'POST',
    maxBodyLength: Infinity,
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collection-video`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    const videoId = response.data.collection_video;
    localStorage.setItem('collectionVideoId', JSON.stringify(videoId));
    if (successCB) successCB(response.data);
  } catch (error) {
    console.error("Error uploading collection video:", error);
    if (failureCB) failureCB(error);
  }
};


/* Save Video API */
export const saveVideo = async (payload, successCB, failureCB) => {
  const data = JSON.stringify({
    video_id: payload.video_id,
  });
  const config = {
    method: 'POST',
    maxBodyLength: Infinity,
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/save-video`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };


  axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (successCB) successCB(response.data);
    })
    .catch((error) => {
      console.error("upload error:", error);
      if (failureCB) failureCB(error);
    });
};


/* Collection List API */
export const collectionlistVideo = async () => {
  const config = {
    method: "GET",
    maxBodyLength: Infinity,
    url: "https://michaelkjaco-streaming-node-app.onrender.com/api/collection-video",
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    return response.data; 
  } catch (error) {
    console.error("Error fetching video data:", error);
    return [];
  }
};

/* Collection Data Show API */

export const collectionVideoData = async (data, successCB, failureCB) => {
  const collectionID = getCollectionIDFromLocalStorage();
  let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collection-video/${collectionID}`,
      headers: {},
      data: data
  };

  axios
      .request(config)
      .then((response) => {
          console.log('API Response:', response.data); 
          if (response.data) {
              successCB(response.data);
          } else {
              failureCB('No data returned from the API');
          }
      })
      .catch((error) => {
          console.error('Error fetching data:', error); 
          failureCB(error); 
      });
};

/* Collection Video Update API */
export const collectionVideoUpdate = async (collectionPayload, successCB, failureCB) => {
  const collectionID = getCollectionIDFromLocalStorage();
  const data = JSON.stringify({
    title: collectionPayload.title,
    des: collectionPayload.des,
    privacy: collectionPayload.privacy,
    image: collectionPayload.image,
    video_id: collectionPayload.video_id,
  });

  const config = {
    method: 'PUT',
    maxBodyLength: Infinity,
    url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collection-video/${collectionID}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    if (successCB) successCB(response.data);
  } catch (error) {
    console.error("Error uploading collection video:", error);
    if (failureCB) failureCB(error);
  }
};

/* Delete Collection Video API */

export const collectionDelete = async (collectionID) => {
  let data = '';

let config = {
  method: 'DELETE',
  maxBodyLength: Infinity,
  url: `https://michaelkjaco-streaming-node-app.onrender.com/api/collections/${collectionID}`,
  headers: { },
  data : data
};

axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});
}

