import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    TextField,
    Button,
    MenuItem,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TablePagination,
    CircularProgress,
    Typography,
    Backdrop,
} from "@mui/material";
import { wildSearchVideos } from "../../Features/Services/Videos";
import { useNavigate } from "react-router-dom";
import Header from "../VideoGallery/Header";
const CustomerList = () => {
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);

    const customersdata = [
        {
            id: 1,
            username: 'mark22',
            customer_name: 'Mark Percy',
            customer_email: "mark22@gmail.com",
            customer_phone: '9833892389',
        },
        {
            id: 2,
            username: 'jully',
            customer_name: 'Jully Smith',
            customer_email: "jully@gmail.com",
            customer_phone: '9890892389',
        },
        {
            id: 3,
            username: 'molly2',
            customer_name: 'Molly wisley',
            customer_email: "molly2@gmail.com",
            customer_phone: '9833892388',
        }
    ]


    const categoryList = [
        {
            id: 1,
            category_id: 121,
            category_name: 'Live Stream'
        },
        {
            id: 2,
            category_id: 122,
            category_name: 'View Live Stream'
        },
        {
            id: 3,
            category_id: 123,
            category_name: 'Create Stream'
        }
    ];

    const collectionList = [
        {
            id: 1,
            collection_id: 121,
            collection_name: 'Live Stream'
        },
        {
            id: 2,
            collection_id: 122,
            collection_name: 'View Live Stream'
        },
        {
            id: 3,
            collection_id: 123,
            collection_name: 'Create Stream'
        }
    ]

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchFieldInitial = {
        collectionName: "",
        categoryName: "",
        newTitle: "",
    };
    const [searchField, setSearchField] = useState(searchFieldInitial);
    const [isSearching, setSearching] = useState(false);

    useEffect(() => {
        searchVideo();
    }, []);

    const searchVideo = () => {
        setSearching(true);
        wildSearchVideos(searchField)
            .then((response) => {
                setRows(response?.data);
            })
            .catch((error) => {
                console.log("error", error);
                setRows([]);
            })
            .finally(() => {
                setSearching(false);
            });
    };

    const resetSearch = () => {
        setSearchField(searchFieldInitial);
        setRows([]);
    };

    const editVideo = (item) => {
        navigate("/editVideo", {
            state: { video_id: item.video_id },
        });
    };

    return (
        <Container disableGutters maxWidth="lg">
            <Grid container spacing={2} alignItems="center" mb={"16px"}>
                <Grid item xs={3}>
                    <TextField label="Search" fullWidth />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        select
                        label="Category"
                        fullWidth
                        value={searchField.categoryName}
                        onChange={(e, value) => {
                            setSearchField({
                                ...searchField,
                                categoryName: e.target.value,
                            });
                        }}
                    >
                        {categoryList.map((item) => (
                            <MenuItem key={item?.category_id} value={item?.category_name}>
                                {item?.category_name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        select
                        label="Collection"
                        fullWidth
                        value={searchField.collectionName}
                        onChange={(e, value) => {
                            setSearchField({
                                ...searchField,
                                collectionName: e.target.value,
                            });
                        }}
                    >
                        {collectionList?.map((item) => (
                            <MenuItem key={item?.collection_id} value={item?.collection_name}>
                                {item?.collection_name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        onClick={searchVideo}
                        variant="contained"
                        color="primary"
                        sx={{ mr: 1 }}
                    >
                        Search
                    </Button>
                    <Button onClick={resetSearch} variant="contained">
                        Reset
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ pt: 3 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S No</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Customer Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customersdata.length > 0
                            ? customersdata
                                //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((customer, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}.</TableCell>
                                        <TableCell>{customer.username}</TableCell>
                                        <TableCell>{customer.customer_name}</TableCell>
                                        <TableCell>{customer.customer_email}</TableCell>
                                        <TableCell>{customer.customer_phone}</TableCell>

                                        <TableCell
                                            sx={{
                                                color: "blue",
                                                textDecorationLine: "underline",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                editVideo(customer);
                                            }}
                                        >
                                            <Button onClick={resetSearch} variant="contained">
                                                Choose
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            : null}
                    </TableBody>
                </Table>
                {customersdata.length === 0 && !isSearching ? (
                    <TableRow
                        sx={{
                            padding: "16px",
                            display: "block",
                            width: "100%",
                        }}
                    >
                        <Typography textAlign={"center"}>No Records Found</Typography>
                    </TableRow>
                ) : null}
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Backdrop
                open={isSearching}
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress size={"48px"} />
            </Backdrop>
        </Container>
    );
};

export default CustomerList;
