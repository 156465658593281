import { useState } from "react";
import { fetchCategories, fetchCollections } from "../Features/Services/Videos";

export const useVideoCollections = () => {
  // search videos state handlers
  const [collectionList, setCollectionList] = useState([]);
  const [isCollectionFetching, setCollectionFetching] = useState(false);
  const [collectionFetchErrorMsg, setCollectionFetchErrorMsg] = useState("");

  const getCollections = async () => {
    setCollectionFetching(true);
    try {
      const { data } = await fetchCollections();
      setCollectionFetching(false);
      setCollectionFetchErrorMsg("");
      setCollectionList(data);
    } catch (error) {
      setCollectionFetching(false);
      setCollectionFetchErrorMsg(error?.message);
    }
  };

  return {
    getCollections,
    collectionList,
    isCollectionFetching,
    collectionFetchErrorMsg,
  };
};
