import { useState } from "react";
import { fetchSpeakers } from "../Features/Services/Videos";

export const useVideoSpeakers = () => {
  // search videos state handlers
  const [speakersList, setSpeakersList] = useState([]);
  const [isSpeakerFetching, setSpeakerFetching] = useState(false);
  const [speakerFetchErrorMsg, setSpeakerFetchErrorMsg] = useState("");

  const getSpeakersList = async () => {
    setSpeakerFetching(true);
    try {
      const { data } = await fetchSpeakers();
      setSpeakerFetching(false);
      setSpeakerFetchErrorMsg("");
      setSpeakersList(data);
    } catch (error) {
      setSpeakerFetching(false);
      setSpeakerFetchErrorMsg(error?.message);
    }
  };

  return {
    getSpeakersList,
    speakersList,
    isSpeakerFetching,
    speakerFetchErrorMsg,
  };
};
