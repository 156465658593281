import React, { createContext, useState, useContext, useEffect } from 'react';

const StreamStatusContext = createContext();

export const StreamStatusProvider = ({ children }) => {
  const [isStreaming, setIsStreaming] = useState(false);

  const [isLiveEnded, setIsLiveEnded] = useState(false);

  const endLive = () => {
    setIsLiveEnded(prev => !prev); // Toggle the state
  };
console.log("PPPPP", isStreaming);


  const startStreaming = () => setIsStreaming(true);
  const stopStreaming = () => setIsStreaming(false);

  return (
    <StreamStatusContext.Provider value={{ isStreaming, startStreaming, stopStreaming, isLiveEnded, endLive }}>
      {children}
    </StreamStatusContext.Provider>
  );
};

export const useStreamStatus = () => {
  const context = useContext(StreamStatusContext);
  if (!context) {
    throw new Error('useStreamStatus must be used within a StreamStatusProvider');
  }
  return context;
};
