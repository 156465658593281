import React, { useEffect, useRef, useState } from 'react';
import { Container, Box } from '@mui/material';
import startMaster from './master';
import { stopMaster } from './master';
import startViewer from './viewer';
import './Reciverstream.css';
import LiveChat from '../LiveChat';
import LoginHeader from '../../Components/LoginHeader';
import FixedBottomNavigation from '../../Components/FixedBottomNavigation';
import io from 'socket.io-client';
import MainHeader from "../../Components/MainHeader";

const socket = io("https://iwc-tv.michaelkjaco.com:3000", {
  transports: ["websocket", "polling"],
  secure: true
});

console.log("socket", socket)

const ReciverLiveStreaming = () => {
  const localViewRef = useRef(null);
  const remoteViewRef = useRef(null);
  const [username, setUsername] = useState('');
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const isStreamStartedRef = useRef(false);

  useEffect(() => {
    socket.on('streamStatus', (status) => {
      console.log("status", status)
      setIsStreaming(status);
    });

    return () => {
      socket.off('streamStatus');
    };
  }, []);

  // User Info for Chat
  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      setUsername(userInfo.username);
    }
  }, []);

  // Start/Stop Viewer Streaming
  useEffect(() => {
    const startViewerStreaming = async () => {
      if (isStreamStartedRef.current) return;

      isStreamStartedRef.current = true;

      const formValues = {
        channelName: 'jaco_live_stream', // Replace with your signaling channel name
        ingestMedia: true,
        sendAudio: true,
        sendVideo: true,
        widescreen: true,
        forceSTUN: false,
        forceTURN: false,
        openDataChannel: false,
        useTrickleICE: true,
        clientId: Math.random(),
        sendHostCandidates: true,
        acceptHostCandidates: true,
        sendRelayCandidates: true,
        acceptRelayCandidates: true,
        sendSrflxCandidates: true,
        acceptSrflxCandidates: true,
        sendPrflxCandidates: true,
        acceptPrflxCandidates: true,
        sendTcpCandidates: true,
        acceptTcpCandidates: true,
        sendUdpCandidates: true,
        acceptUdpCandidates: true
      };
    

      try {
        await startMaster(localViewRef.current, remoteViewRef.current, formValues, onStatsReport, onRemoteDataMessage);
        await startViewer(localViewRef.current, remoteViewRef.current, formValues, onStatsReport, onRemoteDataMessage);

        setIsChatVisible(true);
      } catch (error) {
        console.error('Error starting viewer streaming:', error);
        setIsChatVisible(false);
      }
    };

    const stopViewerStreaming = () => {
      if (!isStreamStartedRef.current) return;
      isStreamStartedRef.current = false;
      stopMaster();
      setIsChatVisible(false);
    };

    if (isStreaming) {
      console.log("stream start", isStreaming)
      startViewerStreaming();
    } else {
      stopViewerStreaming();
    }

    return () => {
      stopViewerStreaming();
    };
  }, [isStreaming]);

  const onStatsReport = (stats) => {
    console.log('Stats report:', stats);
  };

  const onRemoteDataMessage = (event) => {
    console.log('Remote data message:', event);
  };
  return (

    <>
    <Box className='reciver-livestream'>
    <MainHeader/>
      {/* <LoginHeader showLive={!isStreaming} /> */}
      <Container className="live-stream-main-cover">
      <div className="live-stream-cover">
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        {/* <Typography variant="h5" gutterBottom>
          View Live Stream
        </Typography> */}
  
        {/* <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            className={`start-live-button ${isStreaming ? 'active-button' : ''}`}
            onClick={startStreaming}
            disabled={isStreaming}
          >
            Join Live
          </Button>
         
        </Box> */}
        <Box mt={4}>
          <video
            ref={localViewRef}
            autoPlay
            playsInline
            muted
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: 8,
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
            }}
          />
        </Box>
        <Box mt={4}>
          <video
            hidden
            ref={remoteViewRef}
            autoPlay
            playsInline
            style={{ maxWidth: '100%', borderRadius: 8, boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}
          />
        </Box>
      </Box>
      </div>
      <div className="chat-cover2">
      {isChatVisible && <LiveChat room={'room1'} userName={username}/>}
      </div>
      </Container>
    </Box>
          <FixedBottomNavigation />

          </>
  );
};

export default ReciverLiveStreaming;
